<template>
  <div id="FinanceiroModal">
    <v-card class="container pa-0">
      <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }"
      class="justify-space-between py-2 px-3">
        <span class="title-page body-1">Financeiro</span>
          <v-btn
          @click="store_Empreendimento.dialogFinanceiro = false"
          icon
          dark
          color="#F2F6F7"
          >
            <v-icon large class="title">mdi-close</v-icon>
          </v-btn>
      </v-card-title>

      <v-card-text class="wrapper pt-1 pb-10">
        <v-container>
          <v-row>
            <v-col class="px-0">
              <v-form 
              ref="form"
              v-model="valid">
                <v-autocomplete
                  v-model="store_Empreendimento.empreendimento_edicao.cod_pessoa_conta"
                  class="mt-n2 mb-0"
                  background-color="#FFF"
                  :items="store_Empreendimento.array_pessoa_conta_cedente"
                  label="Nome e conta do cedente"
                  filled
                  item-value="cod_pessoa_conta"
                  item-text="nome"
                  required
                  dense
                  :rules="indiceRules"
                >
                </v-autocomplete>
                <!-- <v-select
                label="Nome e conta do cedente"
                v-model="dados.nome_conta_cedente"
                class="mb-0"
                filled
                dense
                background-color="#FFF"
                :items="opcoes_nome"
                item-text="name"
                :rules="nomeCedenteRules"
                ></v-select> -->

                <v-text-field
                v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_local_pgto"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Local de pagamento"
                placeholder="Local de pagamento"
                filled
                required
                dense
                :rules="localRules"
                ></v-text-field>

                <fieldset class="d-flex flex-column rounded mt-n2 mb-6 pt-2 px-3" style="border: 1px solid #c4c4c4">

                <legend class="ml-4 font-weight-medium body-2" v-bind:style="{ 'color':COR_SUBTITULO }">Instruções Entradas</legend>

                  <v-select
                  label="Entradas (data limite para pagamento com no máximo)"
                  v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_instr1_ent"
                  class="mb-0"
                  filled
                  dense
                  background-color="#FFF"
                  :items="opcoes_entrada_dt"
                  item-text="name"
                  item-value="value"
                  :rules="dataRules"
                  ></v-select>

                  <v-text-field
                  v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_instr2_ent"
                  class="mt-n2 mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Instruções entradas"
                  placeholder="Instruções entradas"
                  filled
                  required
                  dense
                  :rules="instrucoesRules"
                  ></v-text-field>
                </fieldset>

                <fieldset class="d-flex flex-column rounded mt-n2 mb-6 pt-2 px-3" style="border: 1px solid #c4c4c4">
                <legend class="ml-4 font-weight-medium body-2" v-bind:style="{ 'color':COR_SUBTITULO }">Instruções Parcelas</legend>  

                  <v-select
                  label="Parcelas (data limite para pagamento com no máximo)"
                  v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_instr1_parc"
                  class="mb-0"
                  filled
                  dense
                  background-color="#FFF"
                  :items="opcoes_parcelas_dt"
                  item-text="name"
                  item-value="value"
                  :rules="dataRules"
                  ></v-select>

                  <v-text-field
                  v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_instr2_parc"
                  class="mt-n2 mb-0"
                  light
                  loader-height="1"
                  background-color="#FFF"
                  label="Instruções parcelas"
                  placeholder="Instruções parcelas"
                  filled
                  required
                  dense
                  :rules="instrucoesRules"
                  ></v-text-field>
                </fieldset>  

                <v-text-field
                v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_cedente"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Código do Cedente"
                placeholder="Código do Cedente"
                filled
                required
                dense
                :rules="codigoRules"
                ></v-text-field>

                <v-text-field
                v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_convenio"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label=" Repetir Cedente ou Antigo"
                placeholder=" Repetir Cedente ou Antigo"
                filled
                required
                dense
                :rules="repetirCedenteRules"
                ></v-text-field>

                <v-text-field
                v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_carteira"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label=" Nome / Tipo da Carteira"
                placeholder=" Nome / Tipo da Carteira"
                filled
                required
                dense
                :rules="nomeRules"
                ></v-text-field>

                <v-text-field
                v-model="store_Empreendimento.empreendimento_edicao.empreend_multa"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Multa Após Vencimento (%)"
                placeholder="Multa Após Vencimento (%)"
                filled
                required
                dense
                :rules="porcentagemRules"
                ></v-text-field>

                <v-text-field
                v-model="store_Empreendimento.empreendimento_edicao.empreend_juros"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Juros Diário após Vencto(%)"
                placeholder="Juros Diário após Vencto(%)"
                filled
                required
                dense
                :rules="porcentagemRules"
                ></v-text-field>

                <v-select
                label="Boleto Registrado"
                v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_registrado"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="opcoes_boleto"
                item-text="name"
                :rules="boletoRules"
                ></v-select>

                <v-autocomplete
                v-model="store_Empreendimento.empreendimento_edicao.empreend_indice.cod_indice"
                class="mt-n2 mb-0"
                background-color="#FFF"
                :items="array_select_indice"
                label="Índice de Correção"
                filled
                item-value="cod_indice"
                item-text="indice_nome"
                required
                dense
                :rules="indiceRules"
                >
                  <template v-slot:append-outer>
                    <v-icon color="primary"
                    >mdi-plus-circle</v-icon>
                  </template>
                </v-autocomplete>

                <v-text-field
                v-model="store_Empreendimento.empreendimento_edicao.empreend_indiceperiodo"
                class="mt-n2 mb-0"
                light
                loader-height="1"
                background-color="#FFF"
                label="Correções a cada"
                placeholder="Correções a cada"
                filled
                required
                dense
                :rules="correcoesRules"
                ></v-text-field>

                <v-autocomplete
                v-model="store_Empreendimento.empreendimento_edicao.cod_indice_acordo"
                class="mt-n2 mb-0"
                background-color="#FFF"
                :items="array_select_indice"
                label="Índice de Acordo Jurídico"
                filled
                return-object
                required
                dense
                item-text="indice_nome"
                 item-value="cod_indice"
                :rules="indiceRules"
                >
                  <template v-slot:append-outer>
                    <v-icon color="primary"
                    >mdi-plus-circle</v-icon>
                  </template>
                </v-autocomplete>

                <v-select
                label="Contar meses de correção à partir das parcelas de Entrada ?"
                v-model="store_Empreendimento.empreendimento_edicao.empreend_indice_entrada"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="opcoes_contar_meses"
                item-text="name"
                :rules="contarMesesRules"
                ></v-select>

                <v-select
                label="Contar sinal de Entrada ?"
                v-model="store_Empreendimento.empreendimento_edicao.empreend_indice_entrada_sinal"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="opcoes_contar_sinal"
                item-text="name"
                :rules="contarSinalRules"
                ></v-select>

                <v-select
                label="Gerar remessa com ordem de baixa após o vencimento do boleto?"
                v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_baixar"
                class="mt-n2"
                filled
                dense
                background-color="#FFF"
                :items="opcoes_gerar_remessa"
                item-text="name"
                :rules="gerarRemessaRules"
                ></v-select>

              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-footer absolute color="#F2F6F7" elevation="0" class="d-flex justify-center pa-3">
        <v-btn
        @click="store_Empreendimento.dialogFinanceiro = false"
        class="mr-4 btn caption font-weight-medium"
        color="primary"
        text
        >
          Cancelar
        </v-btn>

        <v-btn
        :disabled="!valid"
        @click="validate()"
        class="btn white--text caption font-weight-medium"
        color="primary accent-4"
        >
          Salvar
        </v-btn>
      </v-footer>
    </v-card>
  </div>
</template>

<script>
import { COR_PRINCIPAL, COR_SUBTITULO } from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";

export default {
  name: "FinanceiroModal",

  components: {
  },

  data() {
    return {

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,  
      store_Empreendimento      : store_Empreendimento,
      array_select_indice: store_Empreendimento.array_select_indice,

      dados: {
        nome_conta_cedente: null,
        local: "",

        instrucoes_entradas_dt: "",
        instrucoes_entradas: "",

        instrucoes_parcelas_dt: "",
        instrucoes_parcelas: "",

        codigo: "",
        repetir_cedente: "",
        nome_tipo_carteira: "",
        multa_vencimento: "",
        juros_vencimento: "",
        boleto_registrado: null,
        indice_correcao: null,
        correcoes_mes: "",
        indice_acordo: null,
        contar_meses_correcao: null,
        contar_sinal_entrada: null,
        gerar_remessa: null,
        observacao: "",
      },

      /* SELECT NOME */
      opcoes_nome: [
        "Nome1", "Nome2", "Nome3",
      ],

      /* SELECT INSTRUÇOES ENTRADA - DATA LIMITE */
      opcoes_entrada_dt: [
        { name: "00 dias", value: "00" },
        { name: "05 dias", value: "05" },
        { name: "10 dias", value: "10" },
        { name: "15 dias", value: "15" },
        { name: "20 dias", value: "20" },
        { name: "25 dias", value: "25" },
        { name: "30 dias", value: "30" },
        { name: "35 dias", value: "35" },
        { name: "40 dias", value: "40" },
        { name: "45 dias", value: "45" },
        { name: "50 dias", value: "50" },
        { name: "55 dias", value: "55" },
        { name: "60 dias", value: "60" },
        { name: "90 dias", value: "90" },
      ],

      /* SELECT INSTRUÇOES PARCELAS - DATA LIMITE */
      opcoes_parcelas_dt: [
        { name: "00 dias", value: "00" },
        { name: "05 dias", value: "05" },
        { name: "10 dias", value: "10" },
        { name: "15 dias", value: "15" },
        { name: "20 dias", value: "20" },
        { name: "25 dias", value: "25" },
        { name: "30 dias", value: "30" },
        { name: "35 dias", value: "35" },
        { name: "40 dias", value: "40" },
        { name: "45 dias", value: "45" },
        { name: "50 dias", value: "50" },
        { name: "55 dias", value: "55" },
        { name: "60 dias", value: "60" },
        { name: "90 dias", value: "90" },
      ],

      /* SELECT BOLETO REGISTRADO */
      opcoes_boleto: [
        "SIM", "NÃO",
      ],

      /* SELECT ÍNDICE DE CORREÇÃO */
      opcoes_indice_correcao: [
        "Índice1", "Índice2", "Índice2",
      ],

      /* SELECT ÍNDICE DE ACORDO */
      opcoes_indice_acordo: [
        "Índice1", "Índice2", "Índice2",
      ],
      
      /* SELECT CONTAR MESES */
      opcoes_contar_meses: [
        "SIM", "NÃO",
      ],

      /* SELECT CONTAR SINAL */
      opcoes_contar_sinal: [
        "SIM", "NÃO",
      ],

      /* SELECT GERAR REMESSA APÓS VENCIMENTO DO BOLETO */
      opcoes_gerar_remessa: [
        "SIM", "NÃO",
      ],

      valid: true,
      form: false,

      localRules: [
        (value) => !!value || "O local é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O local deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O local deve ter menos do que 60 caracteres",
      ],
      instrucoesRules: [
        (value) => !!value || "A instrução é obrigatória",
        (value) =>
          (value && value.length >= 3) ||
          "A instrução deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "A instrução deve ter menos do que 60 caracteres",
      ],
      repetirCedenteRules: [
        (value) => !!value || "Repetir cedente ou antigo é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "Repetir cedente ou antigo ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "Repetir cedente ou antigo deve ter menos do que 60 caracteres",
      ],
      nomeRules: [
        (value) => !!value || "O nome/tipo de carteira é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome/tipo de carteira ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O nome/tipo de carteira deve ter menos do que 60 caracteres",
      ],
      codigoRules: [
        (value) => !!value || "O código é obrigatório",
        (value) => /^\d+$/.test(value) || 'O código não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "O código deve ter menos do que 10 caracteres",
      ],
      porcentagemRules: [
        (value) => !!value || "A porcentagem é obrigatória",
        (value) => /^\d+$/.test(value) || 'A porcentagem não é válida',
        (value) =>
          (value && value.length <= 10) ||
          "A porcentagem deve ter menos do que 10 caracteres",
      ],
      correcoesRules: [
        (value) => !!value || "Correções é obrigatório",
        (value) => /^\d+$/.test(value) || 'Correções não é válido',
        (value) =>
          (value && value.length <= 10) ||
          "Correções deve ter menos do que 10 caracteres",
      ],
      nomeCedenteRules: [
        (value) => !!value || "O nome é obrigatório",
      ],
      dataRules: [
        (value) => !!value || "A data é obrigatória",
      ],
      boletoRules: [
        (value) => !!value || "O boleto é obrigatório",
      ],
      indiceRules: [
        (value) => !!value || "O índice é obrigatório",
      ],
      contarMesesRules: [
        (value) => !!value || "Contar meses de correção é obrigatório",
      ],
      contarSinalRules: [
        (value) => !!value || "Contar sinal de entrada é obrigatório",
      ],
      gerarRemessaRules: [
        (value) => !!value || "Gerar remessa é obrigatório",
      ],
    };
  },
 
  methods: {
    
    validate () {
      this.store_Empreendimento.docFinanceiro = 1;
      this.store_Empreendimento.dialogFinanceiro = false;
      this.$refs.form.validate();
    },
  },
};
</script>

<style scoped>
.container {
  background: #F2F6F7 !important;
}

.title-page {
  font-family: "Open Sans", sans-serif!important;
  color: #FFFFFF;
  letter-spacing: 0px;
}

.wrapper {
  height: 460px;
  overflow-y: auto;
}

/* ---------- BARRA DE SCROLL ---------- */
.wrapper::-webkit-scrollbar {
  width: 5px;
}

.wrapper::-webkit-scrollbar-button {
  padding: 1px;
}

.wrapper::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
  height: 10px!important;
}

.wrapper::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.btn {
  width: 100px;
}
</style>