<template>
  <div id="EmpreendModeloDocumento" >

    <!-- <EmpreendimentoModeloTeste /> -->
    <!-- <Empreend_Modelo_Open/> -->

    <v-tabs
      id="tabs"
      v-model="tab"
      background-color="transparent"
      slider-size="2"
      slider-top="10"
      tabindex="-1"
      active-class="active"
      small
    >
      <!-- Propostas -->
      <v-tab
        id="tab"
        class="text-white text-none mt-4 ml-4 "
        @click="carregaModeloPorAba('proposta')"
        :style="{ 'background-color': tab == 0 ? '#FFFFFF' : 'transparent', 'height': '30px' }"
        tabindex="-1"
      > 
        <p
          class="mt-3"
          :style="{ 'color': tab == 0 ? COR_SUBTITULO : '#A0A0A0', 'font-size':'13px'}">
          Propostas
        </p>
      </v-tab>

      <!-- Contratos -->
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-4 ml-4 "
        @click="carregaModeloPorAba('contrato')"
        v-bind:style="{ 'background-color': tab == 1 ? '#FFFFFF' : 'transparent', 'height': '30px' }"
        tabindex="-1"
      >
        <p
          class="mt-3"
          :style="{ 'color': tab == 1 ? COR_SUBTITULO : '#A0A0A0', 'font-size':'13px'}">
          Contratos
        </p>
      </v-tab>

      <!-- Cessão de Direitos -->
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-4 ml-4 "
        @click="carregaModeloPorAba('transferencia')"
        v-bind:style="{ 'background-color': tab == 2 ? '#FFFFFF' : 'transparent', 'height': '30px' }"
        tabindex="-1"
      >
        <p
          class="mt-3"
          :style="{ 'color': tab == 2 ? COR_SUBTITULO : '#A0A0A0', 'font-size':'13px'}">
          Cessão de Direitos
        </p>
      </v-tab>

      <!-- Termo de Quitação -->
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-4 ml-4 "
        @click="carregaModeloPorAba('quitacao')"
        v-bind:style="{ 'background-color': tab == 3 ? '#FFFFFF' : 'transparent', 'height': '30px' }"
        tabindex="-1"
      >
        <p
          class="mt-3"
          :style="{ 'color': tab == 3 ? COR_SUBTITULO : '#A0A0A0', 'font-size':'13px'}">
          Termo de Quitação
        </p>
      </v-tab>

      <!-- Autorização de Escritura -->
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-4 ml-4 "
        @click="carregaModeloPorAba('autorizacao')"
        v-bind:style="{ 'background-color': tab == 4 ? '#FFFFFF' : 'transparent', 'height': '30px' }"
        tabindex="-1"
      >
        <p
          class="mt-3"
          :style="{ 'color': tab == 4 ? COR_SUBTITULO : '#A0A0A0', 'font-size':'13px'}">
          Autorização de Escritura
        </p>
      </v-tab>

      <!-- Rescisão -->
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-4 ml-4 "
        @click="carregaModeloPorAba('rescisao')"
        v-bind:style="{ 'background-color': tab == 5 ? '#FFFFFF' : 'transparent', 'height': '30px' }"
        tabindex="-1"
      >
        <p
          class="mt-3"
          :style="{ 'color': tab == 5 ? COR_SUBTITULO : '#A0A0A0', 'font-size':'13px'}">
          Rescisão
        </p>
      </v-tab>

          <!-- <v-btn 
            style=" width: 84px; min-height: 38px; height: 38px; "
            @click="Novo()"
            class="btn caption font-weight-medium"
            elevation="0"
            v-bind:style="{ 'color':COR_SUBTITULO }">
            <v-icon color="green">mdi-plus</v-icon> Novo
          </v-btn>

          <v-menu offset-y left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn 
                style="min-width:4px; width: 4px; height: 38px; padding-left: 10px !important;"
                v-bind="attrs"
                v-on="on"
                class="btn pr-3"
                elevation="0">
                <v-icon class="btn-icon" color="green accent-4">mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list class="py-0">
              <v-list-item
                v-for="(item, i) in news"
                :key="i"
                @click="() => {}"
                class="px-2"
              >
                <v-list-item-title
                  v-if="item.title == 'Importar do Excel'"
                  class="body-2"
                >
                  <div @click="store_Empreendimento.itemSelecionado = 20" class="body-2">
                    <v-icon v-bind:style="{ 'color':COR_SUBTITULO }" class="mr-2 btn-icon"
                      >mdi-table-arrow-up
                    </v-icon>
                    {{ item.title }}
                  </div>
                </v-list-item-title>

                <v-list-item-title
                  v-if="item.title == 'Cadastro Sequencial'"
                  >
                  <div @click="Novo(); store_Empreendimento.acao = 'IS'" class="body-2">
                    <v-icon v-bind:style="{ 'color':COR_SUBTITULO }" class="mr-2 btn-icon"
                      >mdi-circle-edit-outline
                    </v-icon>
                    {{ item.title }}
                  </div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>   -->

    </v-tabs>

    <v-row class="ml-2 mr-2">
      <v-container class="container mt-3 mx-0 pt-6 rounded-lg">
        <!-- <v-menu
          content-class="my-menu"
          transition="slide-y-transition"
          bottom
          class="myBtn"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('pessoa_privilegios_alterar')"
              color="grey"
              dark
              icon
              v-bind="attrs"
              v-on="on"
              class="mt-n1 ml-1 mb-3" 
              id="myBtn"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template> -->
        
          <!-- Menu de Todas as Aplicações -->
          <!-- <v-card elevation="0" :border="false" style="background-color:#FFFFFF">
            <v-list-item
              @click="adicionarTodos()">
              <v-icon color="green" class="mr-2 icon-menu">
                mdi-pencil
              </v-icon>
              Adicionar eventos de todas as aplicações
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="removerTodos()">
              <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>
              Remover eventos de todas as aplicações
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="dialogoUsuarios = true">
              <v-icon color="blue" class="mr-2 icon-menu">mdi-account-switch</v-icon>
              Copiar os privilégios de outro usuário
            </v-list-item>
          </v-card> -->
          <!-- FIM Adicionar de Todas as Aplciações -->
        <!-- </v-menu> -->

        <!-- <v-spacer></v-spacer> -->
        <v-menu
          content-class="my-menu"
          transition="slide-y-transition"
          bottom
          width="200px"
        >
          <template v-slot:activator="{ on, attrs }">
            <!-- @click="topFunction()" -->
            <!-- faz parte do botão abaixo
            v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('pessoa_privilegios_alterar')": -->
            <v-btn 
              :color="COR_PRINCIPAL"
              id="myBtn"
              fab
              v-bind="attrs"
              v-on="on"
            >
            <v-icon color="white" size="x-large">mdi-plus</v-icon>
          </v-btn>
          </template>
        
          <!-- Menu de Todas as Aplicações -->
          <!-- <div style="background-color: #FF0000; width: 100%; height: 100px; align-content: right!important;">
            <v-icon right large style="color:#FFFFFF; background-color: transparent;">mdi-menu-up</v-icon>
          </div> -->
          <v-card elevation="0" :border="false" style="background-color:#FFFFFF; min-width:370px">
            <v-list-item
              @click="dialog_novo_modelo_limpo = true">
              <v-icon color="green" class="mr-2 icon-menu">
                mdi-plus 
              </v-icon>
              Criar novo modelo limpo
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="getFileId()">
              <v-icon color="blue" class="mr-2 icon-menu">mdi-plus-box-multiple-outline</v-icon>
              Criar modelo à partir de outro existente
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item
              @click="dialog_novo_modelo_upload = true">
              <v-icon color="orange" class="mr-2 icon-menu">mdi-file-upload-outline</v-icon>
              Criar modelo à partir de arquivo (upload)
            </v-list-item>
          </v-card>
          <!-- FIM Adicionar de Todas as Aplciações -->
        </v-menu>


      <!-- <PrivilegioUsuarioAplicacao
        v-if="tab == 0"
        class="mt-9"
        style="width:100%"
      />
      <PrivilegioUsuarioEmpreendimento
        v-if="tab == 1"
        class="mt-9"
        style="width:100%"
      /> -->

      <!-- <v-row dense class="px-0 pl-11">
        <v-col
          v-for="(tipo, i) in empreendimento_doc"
          :key="i"
          class="col-wrapper mr-6 mb-6 pa-0"
        >

          <div class="container_btn-icon rounded">
            <v-btn icon dark class="btn-icon mr-1 mb-4" color="grey">
              <v-icon color="#84A0CD" class="icon-action"> mdi-pencil </v-icon>
            </v-btn>

            <v-btn icon dark class="btn-icon" color="grey">
              <v-icon color="#f44336e6" class="icon-action"> mdi-delete </v-icon>
            </v-btn>
          </div>

          <v-card
          elevation="0"
          class="card pt-3">

            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-img
              :src="tipo.imagem"
              class="card-image ma-3 mt-0"
            ></v-img>

            <v-card-text class="d-flex justify-space-between py-0 pb-1">
               <v-tooltip top>
                 <template v-slot:activator="{ on, attrs }">
                  <div   
                  v-bind="attrs"
                  v-on="on" v-text="tipo.tipo"
                  class="document-name caption">
                  </div>
                 </template>

                 <span v-text="tipo.tipo">teste</span>
               </v-tooltip> 
              <div v-text="tipo.data_hora" class="caption"></div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row> -->

      <v-row dense class="px-0 pl-11">
        <v-col
          v-for="(tipo, i) in array_modelos"
          :key="i"
          class="col-wrapper mr-6 mb-6 pa-0">

          <div
            class="container_btn-icon rounded">
            <v-btn
              @click="AbrirModeloEditor('ABRIR_MODELO', tipo.cod_modelo_documento, tipo.titulo)"
              icon
              dark
              class="btn-icon mr-1 mb-4"
              color="grey">
              <v-icon
                color="#84A0CD"
                class="icon-action">
                mdi-pencil
                </v-icon>
            </v-btn>

            <v-btn
              icon
              dark
              @click="dialogDelete(tipo)"
              class="btn-icon"
              color="grey">
              <v-icon
                color="#f44336e6"
                class="icon-action">
                mdi-delete
                </v-icon>
            </v-btn>
          </div>
          
          <v-card
            v-if="tipo.filename"
            elevation="0"
            class="card pt-1 ">
            <template slot="progress">
              <v-progress-linear
                color="deep-purple"
                height="10"
                indeterminate
              ></v-progress-linear>
            </template>

            <v-card-text class="pa-0 pl-3 pb-1">
              <v-tooltip top>
                 <template v-slot:activator="{ on, attrs }">
                  <div   
                    v-bind="attrs"
                    v-on="on" v-text="tipo.titulo"
                    class="caption">
                  </div>
                 </template>
               </v-tooltip> 

            </v-card-text>
            <!-- src="http:/localhost:30001/editor_documentos_thumb/empresa_000001/modelo_empreend/proposta/modelo_empreend_proposta_000001_000031_000034.png" -->
            <!-- src="http://localhost:30001/imagens/modelo_documento/empresa_1/modelo_documento.jpeg" -->

            <div style="margin-left: 12px; vertical-align:middle; text-align:center; width: 240px; height: 357px; border: 1px solid silver; background-color: #FFFFFF; ">
              <img :src="ImagemEditorDocumentoThumb(tipo.tipo, tipo.filename)"
                style="max-width: 238px; max-height: 355px; height: auto; width: auto">
            </div>

            <v-card-text
              class="pa-0 pl-3 pb-1">
            
              <div  class="caption">{{ formatDate(tipo.cadastro_dt) }}</div>
            </v-card-text>
            <!-- <iframe class="frame" frameBorder="0" width='100%' style="height:400px" src="http://86.48.28.195/example/editor?fileName=sample.docx&lang=pt&userid=uid-1&directUrl=false&type=embedded"></iframe> -->

          </v-card>
        </v-col>
      </v-row>


      </v-container>

    </v-row>

  <!-- Dialogo novo modelo de documento limpo -->
  <v-container
      v-if="dialog_novo_modelo_limpo">
      <v-dialog v-model="dialog_novo_modelo_limpo" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
          <v-divider></v-divider>
          <v-card elevation="0" class="">
            <v-card-title class="text-h6" style="word-break: keep-all">
              Qual o título do documento?
              
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid">
                <v-text-field
                  v-model="titulo_documento"
                  class="input-inscricao mb-0"
                  background-color="#FFF"
                  label="Título do documento"
                  placeholder="Título do documento"
                  filled
                  outlined
                  :rules="rulesTitulo"
                  dense>
                </v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn class="mr-4" color="primary" text @click="dialog_novo_modelo_limpo = false">
                Cancelar
              </v-btn>
              <v-btn 
                class="btn white--text" 
                color="primary accent-4" 
                :loading="loading"
                :disabled="!valid"
                @click="salvarNovoModelo('NOVO_MODELO_LIMPO')">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
  <!-- Fim Dialogo novo modelo de documento limpo -->

  <!-- Dialogo novo modelo de documento por Upload -->
  <v-container
      v-if="dialog_novo_modelo_upload">
      <v-dialog v-model="dialog_novo_modelo_upload" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
          <v-divider></v-divider>
          <v-card elevation="0" class="">
            <v-card-title class="text-h6" style="word-break: keep-all">
              Qual o título do documento?
            </v-card-title>
            <v-card-text>
              <v-form
                ref="form"
                v-model="valid">
                <v-text-field
                  v-model="titulo_documento"
                  class="input-inscricao mb-0"
                  background-color="#FFF"
                  label="Título do documento"
                  placeholder="Título do documento"
                  filled
                  outlined
                  :rules="rulesTitulo"
                  dense>
                </v-text-field>
                <v-file-input
                  v-model="arquivo_upload"
                  accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  chips
                  class="input-upload"
                  label="Arquivo Word">
                </v-file-input>

              </v-form>
            </v-card-text>
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn class="mr-4" color="primary" text @click="dialog_novo_modelo_upload = false; arquivo_upload = null;">
                Cancelar
              </v-btn>
              <v-btn 
                class="btn white--text" 
                color="primary accent-4" 
                :loading="loading"
                :disabled="!valid"
                @click="salvarNovoModelo('NOVO_MODELO_UPLOAD', arquivo_upload)">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
  <!-- Fim Dialogo novo modelo de documento por Upload -->

  <!-- Dialogo para exclusão -->
  <v-container
      v-if="dialog_excluir">
      <v-dialog v-model="dialog_excluir" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
          <v-divider></v-divider>
          <v-card elevation="0" class="">
            <v-card-title class="text-h6" style="word-break: keep-all">
              Tem certeza que deseja EXCLUIR este documento?
            </v-card-title>
            <v-card-text>
              Título: {{titulo_Aux}}<br>
              Data cadastro: {{ formatDate(cadastro_dt_Aux) }}<br>
            </v-card-text>
            
            <v-card-actions class="d-flex justify-center">
              <v-spacer></v-spacer>
              <v-btn class="mr-4" color="primary" text @click="dialog_excluir = false">
                Cancelar
              </v-btn>
              <v-btn 
                class="btn white--text" 
                color="primary accent-4" 
                :loading="loading"
                @click="excluirModelo()">
                Salvar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </v-container>
      <!-- Fim Dialogo para exclusão -->
  </div>
</template>

<script>
import { COR_PRINCIPAL, COR_SECUNDARIA, COR_SECUNDARIA_2, COR_SUBTITULO, COR_BACKGROUND_2 } from "../../../services/constantes";
// import PrivilegioUsuarioAplicacao from "../../Configuracoes/Privilegios/PrivilegioUsuarioAplicacao.vue";
// import PrivilegioUsuarioEmpreendimento from "../../Configuracoes/Privilegios/PrivilegioUsuarioEmpreendimento.vue";
// import store_Pessoa from "../store_Pessoa"
import store_usuario from "../../../store/store_usuario";
import store_site from "../../../store/store_site";
// import EmpreendimentoModeloTeste from "./EmpreendimentoModeloTeste";
//import Empreend_Modelo_Open from "./Empreend_Modelo_Open";
import axios from "axios";
import path from "path";
import { API, baseURL } from "../../../services/API"
import { formatDate, getFileName } from "../../../services/funcoes";
import { PATH_EDITOR_DOCUMENTOS, PATH_EDITOR_DOCUMENTOS_THUMB } from "../../../services/constantes";

const FiletoBase64 = file => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = reject;
});

export default {

  name: "EmpreendModeloDocumento",

  components: {
  },

  props: ['IsMobile'],

  data() {
    return { 
      //store_Pessoa      : store_Pessoa,
      store_site        : store_site,
      store_usuario     : store_usuario,

      COR_PRINCIPAL     : COR_PRINCIPAL,
      COR_SUBTITULO     : COR_SUBTITULO,
      COR_SECUNDARIA    : COR_SECUNDARIA,
      COR_SECUNDARIA_2  : COR_SECUNDARIA_2,
      COR_BACKGROUND_2  : COR_BACKGROUND_2,

      ACAO              : '',
      ARQUIVO           : '',
      USER_ADDRESS      : '',
      TIPO              : 'proposta',

      expand                    : true,
      tab                       : 0,
      itemSelecionado           : 0,
      menu                      : false,
      dialog_novo_modelo_limpo  : false,
      dialog_excluir            : false,
      titulo_documento          : null,
      dialog_novo_modelo_upload : false,
      arquivo_upload            : null,
      loading                   : false,
      valid                     : false,
      array_modelos             : [],
      titulo_Aux                : null,
      cadastro_dt_Aux           : null,
      cod_modelo_documento_aux  : null,
      formatDate                : formatDate,
      timerInterval             : null,

      rulesTitulo: [
        (value) => !!value || "O título é obrigatório",
      ],   

    }
  },

  created() {
    // document.onfocus = function() {
    //   console.log('FOCO', new Date());
    // };
    // console.log('CREATED')
  },

  destroyed() {
    console.log('DESTROYED')
    clearInterval(this.timerInterval);
  },

  mounted() {
    this.carregaModeloInicial();

    this.timerInterval = setInterval(() => {
      console.log('11111');
      this.carregaModeloPorAba(this.TIPO, false);
    }, 10000);    

  },

  watch:  {
    // Quando mudar a tab, já setar o TIPO
    tab(val) {
      switch (val)
        {
          case 0 : { this.TIPO = 'proposta';      break };
          case 1 : { this.TIPO = 'contrato';      break };
          case 2 : { this.TIPO = 'transferencia'; break };
          case 3 : { this.TIPO = 'quitacao';      break };
          case 4 : { this.TIPO = 'autorizacao';   break };
          case 5 : { this.TIPO = 'rescisao';      break };
        }
    }

  },

  methods : {
    ImagemEditorDocumentoThumb(tipo, filename) {
      if (this.TIPO == tipo) {
        const ls_Empresa = store_usuario.user.cod_empresa.toString().padStart(6, '0');
        let ls_FileNameOnly = filename.substring(0, filename.lastIndexOf("."));
        const img = baseURL + path.join(PATH_EDITOR_DOCUMENTOS_THUMB, 
                              `empresa_${ls_Empresa}`,
                              `empreend_modelo/${this.TIPO}`,
                              `${ls_FileNameOnly}.png`)
        return `${img}?t=${new Date().getTime()}`;
      }
      else
        return null
    },

    async excluirModelo() {
      this.loading = true

      const ls_Resp = await API.delete(`/modelo_documento/${this.cod_modelo_documento_aux}`);

      if (ls_Resp.data.result.message == "success") {
        this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Resp.data.result.result.trim();
          this.store_site.alert = true;

        const i = this.array_modelos.findIndex(element => element.cod_modelo_documento == this.cod_modelo_documento_aux)
        if (i !== -1) {
          this.array_modelos.splice(i, 1)
        }

      } else {
          this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Resp.data.result.trim();
          this.store_site.alert = true;
      }
      this.loading = false
      this.dialog_excluir = false;

    },

    dialogDelete(elem){
      // console.log("🚀 ~ file: EmpreendModeloDocumento.vue:587 ~ dialogDelete ~ elem:", elem)
      this.titulo_Aux = elem.titulo
      this.cadastro_dt_Aux = elem.cadastro_dt
      this.cod_modelo_documento_aux = elem.cod_modelo_documento
      this.dialog_excluir = true
    },

    async carregaModeloInicial() {
      this.store_site.overlay_global = true
      const resposta = await API.get("modelo_documento", {params: {tipo: 'proposta', cod_empreendimento: this.$route.params.cod_empreendimento}})
      this.array_modelos = resposta.data.result
      this.store_site.overlay_global = false
    },

    async carregaModeloPorAba(val, overglobal = true) {
      this.store_site.overlay_global = overglobal
      const resposta = await API.get("modelo_documento", {params: {tipo: val, cod_empreendimento: this.$route.params.cod_empreendimento}})
      this.array_modelos = resposta.data.result
      this.store_site.overlay_global = false
    },

    async salvarNovoModelo(acao, arquivo_upload) {
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {

        let ls_FileBase64 = '';
        if (arquivo_upload)
          ls_FileBase64 = await FiletoBase64(arquivo_upload);
        
        // Montando parametros para poder gerar o thumb em branco e salvando o documento
        const ls_Empresa            = store_usuario.user.cod_empresa.toString().padStart(6, '0');
        const ls_CodEmpreendimento  = this.$route.params.cod_empreendimento.toString().padStart(6, '0');
        var lo_JSON = {
          cod_empreendimento : this.$route.params.cod_empreendimento,
          tipo               : this.TIPO,
          titulo             : this.titulo_documento,
          conteudo           : ls_FileBase64,
          caminho            : `empreend_modelo`,
          filename           : `empreend_modelo_${this.TIPO}_${ls_Empresa}_${ls_CodEmpreendimento}_??????.docx`
        };
        var ls_Res;
        ls_Res = await API.post(`/modelo_documento`, JSON.stringify(lo_JSON));
        if (ls_Res.data.result && ls_Res.data.result.message && ls_Res.data.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 1500;
          this.store_site.alert_msg = ls_Res.data.result.result.trim();
          this.store_site.alert = true;
          this.arquivo_upload   = null;

          const resposta = await API.get("modelo_documento", {params: {tipo: this.TIPO}})
          this.array_modelos = resposta.data.result

          // Criando Novo modelo Word do documento recem criado anteriormente no banco de dados
          if (ls_Res.data?.result?.data?.cod_modelo_documento) {
            this.AbrirModeloEditor( acao, 
                                    ls_Res.data.result.data.cod_modelo_documento,
                                    ls_Res.data.result.data.titulo);

            // Novo Modelo em Branco            
            if (acao == 'NOVO_MODELO_LIMPO') {
            }
            // Novo Modelo baseado em arquivo enviado de upload, portanto, copiar o arquivo antes de abrir o documento
            else if (acao == 'NOVO_MODELO_UPLOAD') {
            }

          }

        } else {
          this.store_site.alert_cor = this.store_site.alert_cor_vermelho;
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.data.result.trim();
          this.store_site.alert = true;
          this.arquivo_upload   = null;
        }
        this.loading = false;
        this.dialog_novo_modelo_limpo  = false;
        this.dialog_novo_modelo_upload = false;
      }
    },

    // Cria Novo modelo Word e abre o Editor para edição
    AbrirModeloEditor(acao, cod_arquivo, titulo) {

      // Seta os atributos para o servidor pegar como referencia e conseguir "enxergar" o que fazer
      const ls_empresa    = this.store_site.cod_empresa.toString().padStart(6, '0');
      const ls_empreend   = this.$route.params.cod_empreendimento.toString().padStart(6, '0');
      const URL_API       = store_usuario.user.url_api;
      this.USER_ADDRESS   = `empresa_${ls_empresa}`;
      this.NOME_ARQUIVO   = cod_arquivo.toString().padStart(6, '0');
      this.ARQUIVO        = `empreend_modelo_${this.TIPO}_${ls_empresa}_${ls_empreend}_${this.NOME_ARQUIVO}.docx`;
      this.CAMINHO        = `empreend_modelo/${this.TIPO}`;
      this.TITULO         = titulo;

      // Para resolver os parametros atraves de props dentro do componente filho, verificar na rota "EmpreendimentoModeloDocumento"
      const lo_Query = {ACAO          : acao,
                                                      ARQUIVO       : this.ARQUIVO,
                                                      USER_ADDRESS  : this.USER_ADDRESS,
                                                      TIPO          : this.TIPO,
                                                      URL_API       : URL_API,
                                                      CAMINHO       : this.CAMINHO,
                                                      TITULO        : this.TITULO,                                                     
                                                    };
      console.log('lo_Query:: ', lo_Query);
      const routeData = this.$router.resolve({ name   : 'EditorDocumento',
                                               query  : lo_Query });
      // Abre o editor em nova tela
      window.open(routeData.href, '_blank');

    },

    // Busca a Chave do arquivo sendo ele já criado
    async getFileId() {
      const API7 = axios.create({
                            baseURL: `${this.URL_SERVER}`,
                            headers: {"ngrok-skip-browser-warning":"any"},
                            responseType: "json",
                          });
      const ls_Resp7 = await API7.get(`/getFileId?fileName=new.docx`);
    }

  },

  computed: {
    isMobile() {
      // console.log("IsMobile", this.IsMobile);
      if (this.IsMobile)
        return this.IsMobile
      else  
        return this.$vuetify.breakpoint.name === "xs";
    },
  },

};
</script>

<style scoped lang="scss">
  // .v-image__image{
  //   max-width: 238.5px;
  //   max-height: 355px;
  //   width: auto;
  //   height: auto;
  // }
   html{
    overflow: hidden;
  }
.title-page {
  // width: 100px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif!important;
  font-size: 17px!important;
}
.v-application--wrap{
  min-height: 107vh;
}

@media(min-width: 1264px){
  .container{
    max-width: 1405px;
  }
}

@media(max-width: 599px) {
  .title-page {
    // width: 100%!important;
  }

  .text-opacity {
    opacity: 0.7;
  }
}

@media(max-width: 375px) {
  .tab-mobile {
    margin-left: -44px;
  }
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.nav-link {
  text-decoration: none;
}

.container {
  // max-width: 100%;
  // height: 100vh !important;
  background-color: #FFF!important;
}

@media(max-width: 599px) {
  .container {
    // max-width: 100% !important;
    height: calc(100vh - 130px)!important;
    margin-top: -4px!important;
  }

  .avatar {
    margin-top: 4px;
  }
}

.card {
  // height: 600px!important;
  width: 265px!important;
  border-radius: 8px;
  // border-bottom-right-radius: 0!important;
  // border-bottom-left-radius: 0!important;
  // background: var(--COR_SECUNDARIA);
  background: #F7F7F7;
  box-shadow: var(--COR_SOMBRA)!important;
}
.card-content {
  height: 100%!important;
}

.wrapper-top {
  height: 105px!important;
}

.header-text {
  font-family: "Open Sans", sans-serif!important;
  color: #505050;
}

@media(max-width: 599px) {
  .wrapper-bottom {
    // width: 100%;
    flex-direction: column!important;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .divisor-vertical {
    display: none;
  }

}
.col-top {
  width: 100vw;
}
.col-esquerda {
  // max-width: 300px!important;
  height: 100%;
  position: relative;
  background-color: var(--COR_BACKGROUND);
  // background-color: #FCFCFC !important;
  margin-top: 0px;
  padding-top: 2px!important;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.size-icon {
  font-size: 20px;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  height: 100%;
  // width: 70%;
  background-color: car(--COR_SECUNDARIA);
}

@media(max-width: 599px) {
  .col-direita {
    padding-right: 4px!important;
    padding-left: 0px!important;
    width: 100%;
  }
}

.list {
  background-color: transparent;
  margin-top: -2px;
  color: var(--COR_SUBTITULO);
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff!important;
}

.bg-active {
  // background-color: var(--COR_PRINCIPAL);
  background-color: #efeff3;
  // color : #0000FF !important;
}

.cabecalho {
  background-color: var(--COR_BACKGROUND) !important;
}

@media(max-width: 599px) {
  .cabecalho {
    padding-bottom: 10px!important;
  }
}

.title-page {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 0px;
  margin-left: 16px !important;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.text-capitalize {
  font-family: "Open Sans", sans-serif!important;
}
.active {
  color: blue;
}

// Herdado da aplicação de Documentos  ===================================
#EmpreendModeloDocumento {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

/* ---------- BARRA DE SCROLL ---------- */
#EmpreendModeloDocumento::-webkit-scrollbar {
  width: 5px;
}

#EmpreendModeloDocumento::-webkit-scrollbar-button {
  padding: 1px;
}

#EmpreendModeloDocumento::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#EmpreendModeloDocumento::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

.title-page {
  font-family: "Open Sans", sans-serif!important;
  letter-spacing: 0px;
  font-size: 13px;
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}
.search-input, .search-input-longo {
  margin: 0 3% 1.2rem 0;
  height: 35px !important;
  max-width: 400px!important;
  min-width: 400px!important;
}

@media(max-width: 1024px) {
 .search-input, .search-input-longo {
    max-width: 250px!important;
    min-width: 250px!important;
  }
}

@media (max-width: 768px) {
  .headline {
    flex-direction: column;
  }

  .container_btn-header {
    margin-left: 0px!important;
  }

  .search-input {
    margin: 0.8rem 3% 1.2rem 0;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    // min-width: calc(100% - 15px)!important;
    // max-width: calc(100% - 15px)!important;
  }
}

@media(max-width: 599px) {
  .search-input {
    margin: 0.8rem 20px 1.2rem 0;
    min-width: auto!important;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    // min-width: calc(100% - 15px)!important;
  }

  .container_btn-header .caption {
    margin-top: 16px!important;
  }
}
  
.select-order {
  max-width: 210px;
}

@media(max-width: 768px) {
  .container_btn-header .caption {
    margin-top: 12px!important;
  }

  .select-order {
    margin-top: 12px!important;
    margin-right: 0px!important;
  }
}

@media(max-width: 599px) {
  .select-order-desktop {
    display: none;
  }

  .select-order {
    max-width: 50px;
  }

  .container_btn-header .caption {
    margin-top: 16px!important;
  }
}

.col-wrapper {
  max-width: 265px;
}

@media(max-width: 1024px) {
  .col-wrapper {
     max-width: 30%!important;
  }
}

@media(max-width: 768px) {
  .col-wrapper {
     max-width: 45%!important;
  }
}

@media(max-width: 599px) {
  .col-wrapper {
     max-width: 46%!important;
  }
}

.col-wrapper .card:last-child {
  margin-right: 0px!important;
}

.col-wrapper {
  position: relative;
}

.card-image {
  object-fit: cover;
}

@media(max-width: 599px) {
  .card-image {
    height: 150px;
  }
}

.document-name {
  max-width: 20ch;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  z-index: 3;
}

@media(max-width: 1024px) {
  .document-name {
    max-width: 15ch;
  } 
}

@media(max-width: 599px) {
  .document-name {
    max-width: 8ch;
  } 
}

@media(max-width: 375px) {
  .document-name {
    max-width: 6ch;
  } 
}

@media(max-width: 360px) {
  .document-name {
    max-width: 5ch;
  } 
}

.container_btn-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0!important;
  visibility: hidden!important;
  -webkit-transition: opacity 0.3s linear;
  -moz-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
  background: rgba(25, 118, 210, 0.2); 
}

.col-wrapper:hover .container_btn-icon {
  visibility: visible!important;
  opacity: 1!important;
}

.btn-icon {
  width: 45px;
  height: 45px;
  background: rgb(255, 255, 255, 0.7);
  transition: 0.3s;
}

@media(max-width: 599px) {
  .btn-icon {
    width: 30px;
    height: 30px;
  }
}

.btn-icon:hover {
  background: #F0F0F0;
}

.icon-action {
  font-size: 30px!important;
}

@media(max-width: 599px) {
  .icon-action {
    font-size: 20px!important;
  }
}

.seta {
  margin-top: -32px;
  transform: translateX(100%);
  left: 1px;
  align-content: left;
}

// Controla a posição e seta do Menu quando ele abre
.my-menu {
  margin-top: 55px;
  contain: initial;
  overflow: visible;
  opacity: 1 !important;
  z-index: 9;
}

.my-menu::before {
  display: block;
  overflow-y: auto;
  position: absolute;
  content: "";
  top: 0;
  right: 31px;
  transform: translateY(-100%);
  width: 10px; 
  height: 13px; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #FFFFFF;
  opacity: 1 !important;
  z-index: 9;
  /*
    background-image: url('#glyph');
    background-size:100% 100%;
    filter: url("../../../assets/seta.svg");
  */
}

#myBtn {
  position: fixed;
  border-radius: 50px;
  right: 30px;
  z-index: 8;
  outline: none;
  cursor: pointer;
  opacity: 0.8;
  width: 45px;
  height: 45px;
  -webkit-animation: fadeInFromNone 1s ease-out;
  -moz-animation: fadeInFromNone 1s ease-out;
  -o-animation: fadeInFromNone 1s ease-out;
  animation: fadeInFromNone 1s ease-out;
}

[class*="icono-arrow4"]{
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-top: 7px solid;
  border-right: 7px solid;
  margin: 15px;
  &[class*="-left"]{
    transform: rotate(45deg);
    &[class*="-up"]{
      transform: none;
    }
    &[class*="-down"]{
      transform: rotate(90deg);
    }
  }
  &[class*="-right"]{
    transform: rotate(-135deg);
    &[class*="-up"]{
      transform: rotate(-90deg);
    }
    &[class*="-down"]{
      transform: rotate(180deg);
    }
  }
  &[class*="-up"]{
    transform: rotate(-45deg);
  }
  &[class*="-down"]{
    transform: rotate(135deg);
  }
}


</style>