<template>
  <div id="EmpreendPrivilegios">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2 mb-4">
      <p class="font-primary font-weight-bold font-weight-medium body-1 mt-2" v-bind:style="{ 'color':COR_SUBTITULO }">
        Privilégios deste empreendimento
      </p>
    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->

    <!-- CONTAINER -------------------------------------------------------->
    <v-container class="container mx-0 pa-0 mt-n4">
      <v-card class="rounded-lg tabela align-center w-100" tabindex="-1" elevation="0">
        <v-text-field
          id="txtBuscar"
          ref="txtBuscar"
          prepend-inner-icon="mdi-magnify"
          dense
          v-model="search"
          flat
          autofocus
          filled
          label="Buscar..."
          clearable
          tabindex="-1"
          style="width:300px"
          class="mt-3 ml-3 mb-n5"
          solo
          background-color="#F4F4F4"
        >
        </v-text-field>

        <!-- TABELA PRIVILEGIOS -------------------------------------------------------->
        <v-data-table
          class="rounded-lg"
          :items="usuarios"
          :items-per-page="20"
          :loading="loading"
          hide-default-header
          hide-default-footer
          :page.sync="currentPage"
          @update:page="handlePageChange"
          loading-text="Carregando...  aguarde..."
          no-data-text="Nenhum Registro Encontrado"
          no-results-text="Nenhum Registro Encontrado"
        >

          <template v-slot:header>
            <thead>
              <tr>
                <th class="ml-8 mt-4" width="20px">
                  <v-menu
                    content-class="my-menu" 
                    transition="slide-y-transition"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <!-- v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('PrivilegioUsuarioEmpreendimento_alterar')" -->
                      <v-btn
                        color="grey"
                        dark
                        icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <!-- Adicionar de Todas as Aplciações -->
                    <v-card elevation="0" :border="false" style="background-color:#FFFFFF">
                      <v-list-item
                        @click="adicionarTodos()">
                        <v-icon color="green" class="mr-2 icon-menu">
                          mdi-pencil
                        </v-icon>
                        Adicionar eventos de todos os empreendimentos
                      </v-list-item>
                      <v-divider></v-divider>
                      <v-list-item
                        @click="removerTodos()">
                        <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>
                        Remover eventos de todos os empreendimentos
                      </v-list-item>
                      <v-divider></v-divider>
                    </v-card>
                    <!-- FIM Adicionar de Todas as Aplciações -->
                  </v-menu>
                </th>
                <th v-for="h in headers_privilegios" :key="h.value" :class="h.class" class="py-2">
                  <span>{{h.text}}</span>
                </th>
              </tr>
            </thead>
          </template>

          <template #item="{ item }">
            <tr >
              <td class="ml-8 mt-4" width="20px">
                <v-menu content-class="my-menu" 
                        transition="slide-y-transition"
                        bottom
                >
                <!-- v-if="store_usuario.priv_busca_privilegios_aplicacao_evento('PrivilegioUsuarioEmpreendimento_alterar')" -->
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="grey"
                      dark
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <!-- Menu Editar / Excluir / etc -->
                  <v-card elevation="0" :border="false" style="background-color:#FFFFFF">
                    <v-list-item
                      @click="adicionarTodosUsuario(item.cod_pessoa)">
                      <v-icon color="green" class="mr-2 icon-menu">
                        mdi-pencil
                      </v-icon>
                      Adicionar eventos deste empreendimento
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="removerTodosUsuario(item.cod_pessoa)">
                      <v-icon color="red" class="mr-2 icon-menu">mdi-delete</v-icon>
                      Remover eventos deste empreendimento
                    </v-list-item>
                    <v-list-item
                      @click="dialogoUsuarios = item.cod_pessoa">
                      <v-icon color="blue" class="mr-2 icon-menu">mdi-account-switch</v-icon>
                      Copiar os privilégios de outro usuário
                    </v-list-item>
                  </v-card>
                  <!-- FIM Menu Editar / Excluir / etc -->

                </v-menu>
              </td>
            
              <td  style="width:350px!important">
                <v-list-item-content>
                  <span style="font-size: 15px; display:block; color:#385986 !important;" class="font-weight-bold">{{ item.pessoa_nome }}</span>
                  <span style="font-size: 12px; display:block; color:#808080 !important;" >{{ item.email }}</span>
                </v-list-item-content>
              </td>

              <td class="">

                <v-col class="py-2 px-0">
                  <!-- <pre>{{ item.emprend_eventos }}</pre> -->
                  <!-- attrArr<pre>{{ attrArr }}</pre> -->
                  <v-select
                    v-model="attrArr"
                    :items="item.emprend_eventos"
                    item-text="evento_nome"
                    item-value="id"
                    label="nenhum privilégio associado a este usuário"
                    chips
                    hide-details="auto"
                    background-color="transparent"
                    multiple
                    solo
                    dense
                    flat
                    deletable-chips
                    hide-selected
                    @change="changed()"
                    class="llabel"
                  >
                    <template v-slot:selection="{ attrs, item, select, selected }">
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        :color="item.evento_cor"
                        close
                        @click="select"
                        @click:close="remove(item.id)"
                        class="chips-font mb-1"
                      >
                        <strong>{{ item.evento_nome }}</strong>
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
              </td>

            </tr> 
          </template>
        </v-data-table>

        <!-- Paginacao -------------------- -->
        <template>
          <div class="paginacao d-flex justify-end align-center">
            <span style="color:grey; font-size:14px" class="mr-3">{{count_dados}} linhas</span>
            <v-pagination
              v-model="page"
              :length="paginas"
              total-visible="5"
              @input="handlePageChange"
            ></v-pagination>
          </div>
        </template>

      <!-- FIM TABELA MENSAGENS -------------------------------------------------------->   
      </v-card>   
    </v-container>
    <!-- FIM CONTAINER -------------------------------------------------------->

    <div style="height:20px"></div>
    <v-footer
      id="PrivilegioUsuarioAplicacao_alterar"
      color="transparent"
      elevation="0"
      class="footer justify-center pt-3 mt-12">
      <v-btn
        id="btn_cancelar"
        ref="btn_cancelar"
        class="mr-4 caption font-weight-medium"
        color="primary"
        text
        @click="cancelar()"
      >
        Cancelar
      </v-btn>

      <v-btn
      :disabled="!valid"
      @click="validate()"
      class="btn caption font-weight-medium white--text"
      color="primary accent-4"
      >
        Salvar
      </v-btn>
    </v-footer>   

    <!-- DIALOGO Usuarios -------------------------------------------------------->
    <v-container v-if="dialogoUsuarios">
      <v-dialog
        v-model="dialogoUsuarios"
        scrollable
        persistent
        max-width="500px"
        max-height="600px"
      >
        <v-card>
          <!-- Cabecalho -->
          <v-card-title :style="{ 'background-color':COR_PRINCIPAL }">
            <v-layout nowrap class="mt-n2" style="width:100%; height:28px">
              <div class="pa-0" style="width:100%">
                <p style="color:white; white-space: pre-line; font-size:16px !important;">
                  Busca de Usuários
                </p>                      
              </div>
              <div class="pa-0 text-center" style="width:40px;">
                <v-btn icon dark v-on:click="dialogoUsuarios = null; store_Pessoa.pessoa_selecionado = null" class="mt-n2">
                  <v-icon large class="title">mdi-close</v-icon>
                </v-btn>
              </div>
            </v-layout>
          </v-card-title>
          
          <!-- Corpo -->
          <v-card-text class="pa-0" style="height: 500px;">
            <PessoaConsultaMobile
            class="mt-n2"
            :editar_="true"
            />
          </v-card-text>

          <v-divider></v-divider>
          
          <!-- Rodape -->
          <v-card-actions class="d-flex justify-center pa-3" :style="{ 'background-color':COR_SECUNDARIA }">
            <v-btn
              @click="dialogoUsuarios = null; store_Pessoa.pessoa_selecionado = null"
              class="mr-4 btn text-none"
              color="primary"
              text
            >
              Cancelar
            </v-btn>
            <v-btn
              class="btn text-none"
              color="primary"
              :disabled="!store_Pessoa.pessoa_selecionado"
              @click="copiarDoUsuario(store_Pessoa.pessoa_selecionado, dialogoUsuarios);"
            >
              Selecionar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

  </div>
</template>

<script>
import { API } from "../../../services/API"
import store_Empreendimento from "../store_Empreendimento"
import store_Proposta from "../../Propostas/store_Proposta";
import store_site from "../../../store/store_site"
import store_usuario from "../../../store/store_usuario";
import store_usuarios from "../../Usuarios/store_Usuarios"
import store_Pessoa from "../../Pessoas/store_Pessoa";
import { COR_PRINCIPAL, COR_SUBTITULO, COR_SECUNDARIA } from "../../../services/constantes";
import { sortBy, buscaSemAcentosJSON } from "../../../services/funcoes"
import PessoaConsultaMobile from "../../Pessoas/PessoaConsultaMobile.vue";

export default {
  name: "EmpreendPrivilegios",

  components: {
    PessoaConsultaMobile
  },

  data() {
    return {

      store_site            : store_site,
      store_usuario         : store_usuario,
      store_Empreendimento  : store_Empreendimento,
      store_Proposta        : store_Proposta,
      store_usuarios        : store_usuarios,
      store_Pessoa          : store_Pessoa,

      COR_SUBTITULO         : COR_SUBTITULO,  
      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_SECUNDARIA        : COR_SECUNDARIA,
      
      usuarios              : [],
      usuarios_Aux          : [],
      valid                 : true,
      loading               : false,
      dialogoUsuarios       : null,

      search                : null,
      attrArr               : [],
      empreend_evento       : [],
      empreend_evento_Aux   : [],
      count_dados           : 0,
      paginas               : 1,
      page                  : 1,
      currentPage           : 1,

      /* TITULOS TABELA PRIVILEGIOS */
      headers_privilegios: [
        // { text: "" },
        { text: "Usuários", value: "Usuarios", class: 'title-text' },
        { text: "Privilégios", value: "Privilegios", class: 'title-text' },
      ],
      
      /* TABELA PRIVILEGIOS */
      // dados_privilegios: [],

    };
  },

  async mounted() {
    console.log("MOUNTED", this.store_Empreendimento.empreendimento_edicao.empreend_evento)
    // await this.busca_aplicacoes();
    await this.busca_Usuarios();
  },

  watch: {
    search(val) {
      console.log('VAL::', val)
      if (!val)
        this.usuarios = this.usuarios_Aux;
      else
        this.usuarios = buscaSemAcentosJSON(this.usuarios_Aux, 'pessoa_nome', val);
    }
  },

  methods: {
    async handlePageChange(value) {
      this.currentPage = value;
      // await this.busca_Usuarios();
    },

    async busca_pessoa_empreend_evento_privilegios(cod_pessoa, cod_empreendimento) {
      const params = {params: {cod_pessoa : cod_pessoa, cod_empreendimento : cod_empreendimento}}
      const response = await API.get("/configuracao/pessoa_empreend_evento_privilegios", params);
      if (Array.isArray(response.data.result))
        if (response.data.result.length > 0) {
        const apps = response.data.result.map(value => {
            return value.cod_empreend_evento;
          })
        return apps;
        }
    },

    async busca_Usuarios() {
      let lo_params = { params: { limit: 99999, page: this.currentPage, cod_empreendimento : this.$route.params.cod_empreendimento }}
      this.loading = true;
      let resp = await API.get("configuracao/pessoa_empreend_unico_evento_privilegios", lo_params );
      const { data } = resp;
      this.attrArr = [];
      if (resp.status == 200 && resp.data.result !== 'Não possui dados') {
        this.usuarios = data.result.rows.filter((data) => {
          if (Array.isArray(this.store_Empreendimento.empreendimento_edicao.empreend_evento))
            if (this.store_Empreendimento.empreendimento_edicao.empreend_evento.length > 0) {
              data.emprend_eventos = this.store_Empreendimento.empreendimento_edicao.empreend_evento.map(value => {
                return {id                  : data.cod_pessoa + '@' + value.cod_empreend_evento,
                        cod_empreend_evento : value.cod_empreend_evento,
                        evento_nome         : value.evento_nome
                      };
              })
            }
          data.usu_priv_emp.map(value => {
            this.attrArr.push(data.cod_pessoa + '@' + value.cod_empreend_evento);
          })
          return data.cod_pessoa > 0 && data.pessoa_nome != null;
        })
        this.usuarios_Aux = this.usuarios;
        this.count_dados = this.usuarios.length
        this.paginas = Math.ceil(this.count_dados / 20)
      }
      else{
        this.usuarios = [];
        this.usuarios_Aux = [];
      }
      this.attrArrAux = [...this.attrArr];
      this.loading = false;
    },

    // Todos -------------------------------------------------------------
    adicionarTodos() {
      const lo_EmpEventos = this.store_Empreendimento.empreendimento_edicao.empreend_evento;
      this.attrArr = [];
      if (Array.isArray(lo_EmpEventos))
        if (lo_EmpEventos.length > 0) {
          lo_EmpEventos.map(evento => {
            this.usuarios.map((usuario) => { 
              this.attrArr.push(usuario.cod_pessoa + '@' + evento.cod_empreend_evento);
            })
          })
        }
    },

    removerTodos() {
      this.attrArr = [];
    },

    // Por Usuario -------------------------------------------------------------
    adicionarTodosUsuario(p_codPessoa) {
      const lo_EmpEventos = this.store_Empreendimento.empreendimento_edicao.empreend_evento;
      let eventos;
      if (Array.isArray(lo_EmpEventos))
        if (lo_EmpEventos.length > 0) {
          eventos = lo_EmpEventos.map(value => {
            return {id                  : p_codPessoa + '@' + value.cod_empreend_evento,
                    cod_empreend_evento : value.cod_empreend_evento,
                    evento_nome         : value.evento_nome
                  };
          })
        }
        if (eventos) {
          eventos.map((o) => { 
            if (!this.attrArr.find((attr) => attr == p_codPessoa + '@' + o.cod_empreend_evento ))
              this.attrArr.push(p_codPessoa + '@' + o.cod_empreend_evento);
          })
        }
    },

    removerTodosUsuario(p_codPessoa) {
      const lo_EmpEventos = this.store_Empreendimento.empreendimento_edicao.empreend_evento;
      let eventos;
      if (Array.isArray(lo_EmpEventos))
        if (lo_EmpEventos.length > 0) {
          eventos = lo_EmpEventos.map(value => {
            return {id                  : p_codPessoa + '@' + value.cod_empreend_evento,
                    cod_empreend_evento : value.cod_empreend_evento,
                    evento_nome         : value.evento_nome
                  };
          })
        }
        if (eventos) {
          eventos.map((o) => { 
            const idx_evento = this.attrArr.find((attr) => attr == p_codPessoa + '@' + o.cod_empreend_evento );
            if (idx_evento) {
              this.attrArr.splice(idx_evento, 1)
              this.attrArr = [...this.attrArr];
            }
          })
        }
    },

    // Adicionar do Usuario -----------------------------------------------------
    async copiarDoUsuario(p_PessoaSelecionado, p_CodPessoa) {
      this.dialogoUsuarios = null;
      const attrArrAux = await this.busca_pessoa_empreend_evento_privilegios( p_PessoaSelecionado.cod_pessoa, this.store_Empreendimento.empreendimento_edicao.cod_empreendimento );
      attrArrAux.map((o) => {
        this.attrArr.push(p_CodPessoa + '@' + o);
      })
    },

    cancelar() {
      this.attrArr = [];
      this.attrArr.push(...this.attrArrAux);
    },

    remove (id) {
      let idx = this.attrArr.indexOf(id)
      this.attrArr.splice(idx, 1)
      this.attrArr = [...this.attrArr]
    },

    async changed(){
      sortBy(this.attrArr, { prop: "id" });
      //this.$emit('changed', this.attrArr)
    },

    async validate() {
      var ls_Res = null;
      let arr = this.attrArr.map((value) => {
                                        return { cod_pessoa: value.split('@')[0], cod_empreend_evento : value.split('@')[1]};
                                        })
//console.log("attrArrAux", attrArrAux);
//       this.store_Empreendimento.empreendimento_edicao.empreend_evento.map(value => {
//         //value.items.map(value2 => {
//         if (this.attrArrAux.includes(value.cod_empreend_evento)) {
// console.log(value);
//           arr.push({descricao: value.descricao, ...value});
//         }
//         //})
//       })
console.log(JSON.parse(JSON.stringify(this.store_Empreendimento.empreendimento_edicao.empreend_evento)), arr);

      ls_Res = await API.post(`/configuracao/priv_empreend_evento_por_empreendimento/${this.store_Empreendimento.empreendimento_edicao.cod_empreendimento}`, JSON.stringify(arr));

      if (ls_Res.status == 200) {
        this.store_site.alert_cor       = "#00A000";
        this.store_site.alert_timeout   = 1500;
        this.store_site.alert_msg       = ls_Res.data.result.result.trim();
        this.store_site.alert           = true;
      } else {
        this.store_site.alert_cor       = "#FF0000";
        this.store_site.alert_timeout   = 10000;
        this.store_site.alert_msg       = ls_Res.data.errors.trim();
        this.store_site.alert           = true;
      }
    }

  },

};
</script>

<style scoped>

#EmpreendPrivilegios {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 4px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #EmpreendPrivilegios {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#EmpreendPrivilegios::-webkit-scrollbar {
  width: 5px;
}

#EmpreendPrivilegios::-webkit-scrollbar-button {
  padding: 1px;
}

#EmpreendPrivilegios::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#EmpreendPrivilegios::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #EmpreendPrivilegios::-webkit-scrollbar {
    width: initial;
  }

  #EmpreendPrivilegios::-webkit-scrollbar-button {
    padding: initial;
  }

  #EmpreendPrivilegios::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #EmpreendPrivilegios::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.divider {
  margin-bottom: 2px;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 1px 4px 0px, #edeff1 0px 1px 3px 0px!important;
}

.chips-font {
  font-weight: 300;
  height: 20px!important;
}

.my-menu {
  margin-top: 40px;
  contain: initial;
  overflow: visible;
}
.my-menu::before {
  position: absolute;
  content: "";
  top: 0;
  left: 10px;
  transform: translateY(-100%);
  width: 10px; 
  height: 13px; 
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #F7F7F7;
}

.llabel >>> label {
  color: rgb(210, 210, 210)!important;
}

</style>
