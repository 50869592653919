<template>
<div id="EmpreendimentoContainer">

  <v-toolbar-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="headline lighten-2">
    <!-- TITULO DA PAGINA -->
    <v-toolbar-title class="text-white title-page body-1 mt-1 ml-3 w-100 mb-0">
      <router-link :to="{ name: 'EmpreendimentoConsulta' }" class="nav-link" aria-current="page">
        <v-btn icon dark color="grey" class="btn-icon mr-1">
          <v-icon color="#84A0CD" class="icon-action"> mdi-chevron-left </v-icon>
        </v-btn>
      </router-link>
      Empreendimento
    </v-toolbar-title>
    <!-- FIM TITULO DA PAGINA -->

    <div class="d-flex d-sm-none mt-n14 mb-6 pa-4 pt-5">
      <v-avatar tile color="grey darken" size="72" class="rounded mr-3">
        <v-avatar class="rounded" color="grey darken" size="70" tile>
          <v-img src="http://192.168.100.58:3003/imagens/imoveis/fotos/empresa_1/005509/005509_000001.png"></v-img>
        </v-avatar>
      </v-avatar>

      <div class="d-flex flex-column align-center mt-n1">
        <div class="d-flex flex-column">
          <span class="body-2 font-weight-medium text-white">{{ dados.nome }}</span>
          <span class="text-opacity body-2 text-white">{{ dados.email }}</span>
          <span class="text-opacity body-2 text-white">{{ dados.telefone }}</span>
        </div>

      </div>
    </div>

    <!-- TABS MOBILE -->
    <div class="d-block d-sm-none mt-n6 mb-2" tabindex="-1">
      <v-tabs v-if="isMobile" v-model="tab" background-color="transparent" dark class="tab-mobile mr-2 ml-2 mt-n3 pa-0" center-active slider-size="2" tabindex="-1" slider-color="#D0D0D0" active-class="active">
        <v-tab v-for="item in abas_mobile" :key="item" class="text-none" tabindex="-1">
        </v-tab>
      </v-tabs>
    </div>
    <!-- FIM TABS MOBILE -->
  </v-toolbar-title>

  <v-container class="container rounded-lg mt-n10 pa-0">
    <v-card elevation="0" class="card d-flex flex-column pb-0">
      <v-card-text class="card-content my-0 py-0 px-2">
        <v-row class="d-none d-sm-block wrapper-top rounded-tl-lg my-0 py-0 px-1">
          <!-- COLUNA TOPO ------------------------------->
          <v-col class="col-top rounded-t-lg ma-0 pa-0">
            <v-sheet class="cabecalho d-flex  mx-auto pa-4 pb-2">
              <v-avatar size="80px" 
                v-ripple  
                class="grey lighten-2">
                <v-img 
                  :src="store_Empreendimento.empreendimento_edicao.empreend_logo" 
                  height="100%">
                </v-img>
              </v-avatar>
              <v-col class="pa-0 pl-1">
                <div class="d-flex justify-space-between">
                  <div>
                    <span class="header-text body-2 font-weight-medium mr-4">Código: <span class="font-weight-bold body-1" v-bind:style="{ 'color':COR_SUBTITULO }">{{ store_Empreendimento.empreendimento_edicao.cod_empreendimento }}</span></span>
                    <span class="header-text body-2 font-weight-medium">Data: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ formatDate(store_Empreendimento.empreendimento_edicao.empreend_matr_data) }}</span></span>
                  </div>
                  <div>
                    <span
                        v-if="store_Empreendimento?.empreendimento_edicao?.empreend_situacao?.toUpperCase() == 'ATIVO'"
                        class="status_ativo"
                        >{{ store_Empreendimento.empreendimento_edicao.empreend_situacao.initCap() }}
                      </span>
  
                      <span
                        v-if="store_Empreendimento?.empreendimento_edicao?.empreend_situacao?.toUpperCase() == 'INATIVO'"
                        class="status_inativo"
                        >{{ store_Empreendimento.empreendimento_edicao.empreend_situacao.initCap() }}
                      </span>
                  </div>
                </div>

                <div>
                  <span
                    class="header-text mr-4">
                    Empreendimento: 
                    <span
                      class=""
                      v-bind:style="{ 'color':COR_SUBTITULO }">
                      {{ store_Empreendimento.empreendimento_edicao.empreend_nome }}
                    </span>
                  </span>
                  <!-- <span
                    class="d-block d-sm-inline header-text mr-4">
                    Situação: 
                    <span
                      class=""
                      v-bind:style="{ 'color':COR_SUBTITULO }">
                      {{ store_Empreendimento.empreendimento_edicao.empreend_situacao }}
                    </span>
                  </span> -->
                  <span class="d-block d-sm-inline header-text">Área total: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ store_Empreendimento.empreendimento_edicao.empreend_area }}</span></span>
                </div>
                <div v-if="!!store_Empreendimento.empreendimento_edicao.empreend_empresa">
                  <span class="header-text mr-4">Administradora: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{ store_Empreendimento.empreendimento_edicao.empreend_empresa.nome }}</span></span>
                </div>
                 <div v-else>
                  <span class="header-text mr-4">Administradora: <span class="" v-bind:style="{ 'color':COR_SUBTITULO }">{{  }}</span></span>
                </div>
                
              </v-col>
            </v-sheet>
            <v-divider class="divisor-horizontal"></v-divider>

          </v-col>
          <!-- FIM COLUNA TOPO ------------------------------->
        </v-row>

        <v-row class="wrapper-bottom my-0 py-0 px-1">
          <!-- COLUNA ESQUERDA ------------------------------->
          <v-col class="col-esquerda shrink d-none d-sm-flex flex-column rounded-tl-lg rounded-bl-lg pa-0" style="background: #F7F7F7;">
            <v-list v-show="expand" class="list rounded-bl-lg overflow-y-scroll pt-0" dense>

              <v-list-item-group
                v-model="store_Empreendimento.itemSelecionado"
                active-class="bg-active"
                color="primary"
                mandatory>
                <template
                  v-for="[icon, text, quant] in store_Empreendimento.abas"
                  link
                  class="px-3">

                  <!-- SEPARADOR ------------------------------->
                  <v-divider v-if="text == 'separador'" :key="icon" class="my-1"></v-divider>
                  <!-- FIM SEPARADOR ------------------------------->
                  <v-list-item 
                    v-else :key="icon"
                    :disabled="store_Empreendimento.acao != 'C'"
                  >
                    <v-list-item-icon v-if="text != 'separador'" class="mr-2">
                      <v-icon class="size-icon">{{ icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content @click="store_Empreendimento.importarPlanilha = false" v-if="text != 'separador'" class="mr-n2">
                      <v-badge icon="mdi-check" :value="quant" color="green accent-9" offset-x="20" offset-y="18">
                      </v-badge>

                      <v-badge 
                        :content="quant" 
                        :value="quant" 
                        color="blue accent-1" 
                        offset-x="20" 
                        offset-y="18">
                      </v-badge>
                      <v-list-item-title class="new-font">{{ text }}</v-list-item-title>
                    </v-list-item-content>

                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>

            <v-btn 
              class="btn-expanded ma-0 pa-0" 
              :style="`${expand ? '' : 'right: -10px!important;'}`" 
              @click="expand = !expand">
              <v-icon 
                v-if="expand == false" 
                class="size-icon-expanded ma-0 pa-0">mdi-chevron-right
              </v-icon>

              <v-icon 
                v-else 
                class="size-icon-expanded ma-0 pa-0">mdi-chevron-left
              </v-icon>
            </v-btn>
          </v-col>
          <!-- FIM COLUNA ESQUERDA ------------------------------->

          <v-divider class="divisor-vertical" vertical></v-divider>

          <!-- COLUNA DIREITA ------------------------------->
          <v-col v-if="store_Empreendimento.itemSelecionado == 0" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <DadosBasicos />
          </v-col>

          <v-col v-if="store_Empreendimento.itemSelecionado == 1" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <Financeiro />
          </v-col>

          <v-col v-if="store_Empreendimento.itemSelecionado == 2" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <EmpreendPrivilegios />
          </v-col>
          <v-col v-if="store_Empreendimento.itemSelecionado == 3" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <Contabil />
          </v-col>

          <v-col v-if="store_Empreendimento.itemSelecionado == 4" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <PlanosPagamento />
          </v-col>

          <v-col v-if="store_Empreendimento.itemSelecionado == 5" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <EmpreendModeloDocumento />
          </v-col>

          <v-col v-if="store_Empreendimento.itemSelecionado == 6" class="col-direita mr-0 ml-1 pt-2 pr-3 pb-2 pl-2">
            <GedDocumentos
              :cod_empreendimento_aux="this.$route.params.cod_empreendimento"
              :paramsTipoDocumento="{tipo_cadastro: 'empreendimento', tipo_cadastro_descricao: 'Documentos Empreendimento'}"
              :paramBusca="{cod_empreendimento: this.$route.params.cod_empreendimento}"
              :statusDocumento="'Aprovado'"/>
          </v-col>

          <v-col v-if="store_Empreendimento.itemSelecionado == 7" class="col-direita mr-n1 ml-1 pt-0 pr-1 pb-0 pl-1">
            <Unidades v-if="!store_Empreendimento.importarPlanilha" />
            <ImoveisPlanilha v-if="store_Empreendimento.importarPlanilha" />

          </v-col>
          <v-col v-if="store_Empreendimento.itemSelecionado == 8" class="col-direita mr-n1 ml-1 pt-2 pr-3 pb-2 pl-2">
            <HistoricoAtividades
              :parametro = "cod_empreendimento"
            />
          </v-col>

          <!-- FIM COLUNA DIREITA ------------------------------->

        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</div>
</template>

<script>
import {
  COR_PRINCIPAL,
  COR_SECUNDARIA,
  COR_SUBTITULO
} from "../../services/constantes";
import DadosBasicos from "./DadosBasicos/DadosBasicos.vue";
import EmpreendPrivilegios from "./Privilegios/EmpreendPrivilegios.vue";
import Financeiro from "./Financeiro/Financeiro.vue";
import Contabil from "./Contabil/Contabil.vue";
import PlanosPagamento from "./PlanosPagamento/PlanosPagamento.vue";
import EmpreendModeloDocumento from './Modelos/EmpreendModeloDocumento.vue';
import GedDocumentos from "../Ged/GedDocumentos.vue";
import Unidades from "./Unidades/Unidades.vue";
import ImoveisPlanilha from "./Unidades/ImoveisPlanilha.vue";
import HistoricoAtividades from "../ComponentesGlobais/Historico/HistoricoAtividades.vue"
// import storePessoa from "./store_Pessoa"
import store_Empreendimento from "./store_Empreendimento";
import store_site from "../../store/store_site";
import { EmpreendimentoPendencias } from "../../services/pendencias"
import {
  formatDateTime,
  formatDate,
} from "../../services/funcoes";

export default {

  components: {
    DadosBasicos,
    EmpreendPrivilegios,
    Contabil,
    Financeiro,
    PlanosPagamento,
    GedDocumentos,
    Unidades,
    ImoveisPlanilha,
    EmpreendModeloDocumento,
    HistoricoAtividades
  },

  data() {
    return {

      // storePessoa        : storePessoa,
      store_Empreendimento  : store_Empreendimento,
      store_site            : store_site,

      COR_PRINCIPAL         : COR_PRINCIPAL,
      COR_SUBTITULO         : COR_SUBTITULO,
      COR_SECUNDARIA        : COR_SECUNDARIA,

      EmpreendimentoPendencias  : [],

      formatDateTime  : formatDateTime,
      formatDate      : formatDate,

      expand: true,
      tab: null,
      cod_empreendimento: '',

      dados: {
        nome: "Fernando Garcia",
        telefone: "(16) 99999-9999",
        email: "john@vuetifyjs.com",
      },

      abas_mobile: [
        'Dados Básicos', 'Documentos', 'Privilégios',
      ],

    }
  },
 
  async mounted() {

    // Edição do cadastro -----------------------------------------
    var lo_params = {
      cod_empreendimento: this.$route.params.cod_empreendimento
    };
    if (lo_params.cod_empreendimento) {
      this.cod_empreendimento = {cod_empreendimento : this.$route.params.cod_empreendimento}
      this.store_site.overlay_global = true
      this.store_Empreendimento.itemSelecionado = 0

      this.store_Empreendimento.empreendimento_edicao.cod_empreendimento = null;
      this.store_Empreendimento.empreendimento_edicao = {};
      this.store_Empreendimento.acao = "C";
      const lo_Res = await this.store_Empreendimento.EmpreendimentoGet(lo_params);

      if (!!lo_Res) {
        this.store_Empreendimento.empreendimento_edicao = { ...lo_Res };
        this.store_site.overlay_global = false
      }
      this.Atualiza_Pendencias(this.$route.params.cod_empreendimento);
    }
    // Inclusão ---------------------------------------------------
    else {
      this.store_Empreendimento.acao = "I";
      this.store_Empreendimento.empreendimento_edicao = {};
    }

  },

  methods:{

    async Atualiza_Pendencias(cod_empreendimento) {
      this.EmpreendimentoPendencias = await EmpreendimentoPendencias({cod_empreendimento : cod_empreendimento});
      this.store_Empreendimento.abas.map((o) => {
        const lo_Find = this.EmpreendimentoPendencias.find(f => f.nome == o[1]);
        // console.log('FIND', lo_Find);
        if (lo_Find) {
          o[2] = lo_Find.valor;
          // console.log('Achou ', o);
        }
      })
      // console.log('EmpreendimentoPendencias:::', store_Empreendimento.abas, this.EmpreendimentoPendencias)
    },

    importaPlanilha(){
      // console.log("🚀 ~ file: EmpreendimentoContainer.vue:320 ~ importaPlanilha ~ this.store_Empreendimento.importarPlanilha:", this.store_Empreendimento.importarPlanilha)
      this.store_Empreendimento.importarPlanilha = false
      // console.log("🚀 ~ file: EmpreendimentoContainer.vue:321 ~ importaPlanilha ~ this.store_Empreendimento.importarPlanilha:", this.store_Empreendimento.importarPlanilha)
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.name === "xs";
    },
  },

};
</script>

<style lang="scss" scoped>
.title-page {
  width: 600px !important;
  height: 80px;
  font-family: "Open Sans", sans-serif !important;
  font-size: 17px !important;
}

@media(max-width: 599px) {
  .title-page {
    width: 100% !important;
  }

  .text-opacity {
    opacity: 0.7;
  }
}

@media(max-width: 375px) {
  .tab-mobile {
    margin-left: -44px;
  }
}

.text-white {
  color: #FFFFFF;
  letter-spacing: 0px;
}

.btn-icon:hover {
  background: rgba(255, 255, 255, 0.1);
}

.btn-icon:hover .icon-action {
  color: #FFF !important;
  opacity: 0.8;
}

.nav-link {
  text-decoration: none;
}

.container {
  max-width: 100%;
  height: calc(100vh - 110px);
}

@media(max-width: 599px) {
  .container {
    max-width: 100% !important;
    height: calc(100vh - 160px) !important;
    margin-top: -4px !important;
  }

  .avatar {
    margin-top: 4px;
  }
}

.card {
  height: 100% !important;
  border-radius: 8px;
  background: var(--COR_SECUNDARIA);
  box-shadow: var(--COR_SOMBRA) !important;
}

.card-content {
  height: 100% !important;
}

.wrapper-top {
  height: 105px !important;
}

.header-text {
  font-family: "Open Sans", sans-serif !important;
  color: #505050;
}

.wrapper-bottom {
  height: calc(100% - 105px) !important;
}

.divisor-horizontal {
  // display: none;
  width: 100% !important;
  position: relative;
}

.divisor-vertical {
  // display: none;
  height: 100% !important;
  position: relative
}

@media(max-width: 599px) {
  .wrapper-bottom {
    width: 100%;
    flex-direction: column !important;
    justify-content: center;
    margin-right: 0px;
    margin-left: 0px;
  }

  .divisor-vertical {
    display: none;
  }
}

.col-top {
  width: 100vw;
}

.col-esquerda {
  max-width: 220px;
  height: 100%;
  position: relative;
  background-color: var(--COR_BACKGROUND) !important;
  margin-top: 0px;
  padding-top: 2px !important;
}

.overflow-y-scroll {
  overflow-y: auto;
}

.size-icon {
  font-size: 20px;
}

.size-icon-expanded {
  font-size: 15px;
}

.col-direita {
  height: 100%;
  width: 70%;
}

@media(max-width: 599px) {
  .col-direita {
    padding-right: 4px !important;
    padding-left: 0px !important;
    width: 100%;
  }
}

.list {
  background-color: transparent;
  min-width: 220px;
  margin-top: -2px;
  color: var(--COR_SUBTITULO);
}

.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #fff !important;
}

.bg-active {
  background-color: var(--COR_PRINCIPAL);
  color: white !important;
}

.cabecalho {
  background-color: var(--COR_BACKGROUND) !important;
}

@media(max-width: 599px) {
  .cabecalho {
    padding-bottom: 10px !important;
  }
}

.status_ativo {
    font-size: 14px !important;
    background: #469c47;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 24px;
  }

  .status_inativo {
    font-size: 14px !important;
    background: #df6262;
    color: #fff;
    padding: 2px 12px;
    border-radius: 25px;
    height: 24px;
  }
</style>
