<template>
  <div id="PlanosPagamento">

    <!-- TÍTULO DA PÁGINA -->
    <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2 mt-n9">
      <p class="font-primary font-weight-bold font-weight-medium body-1 " :style="{ 'color':COR_SUBTITULO }">
        Planos de Pagamento
      </p>

      <v-btn @click="novo()" class="btn mr-1 pr-6 " elevation="0">
        <v-icon class="btn-icon mr-1" color="green accent-4">mdi-plus </v-icon>
        <span class="caption font-weight-medium" v-bind:style="{ 'color':COR_SUBTITULO }">Novo</span>
      </v-btn>


    </v-toolbar-title>
    <!-- FIM TÍTULO DA PÁGINA -->
    <!-- CONTAINER "LABELS" -------------------------------------------------------->
    <v-form
      v-model="valid"
      ref="form"
      class="mx-1 mt-1"
      lazy-validation>
      <v-container class="">
        <v-row class="pt-0">
          <!-- PRIMEIRA COLUNA / SITUACAO ------------------------>
          <v-col
            v-if="store_Empreendimento.acao != 'I'"
            cols="12"
            sm="12"
            class="px-2 pb-0 pt-0 mb-1">
            <span>
              Selecione o plano de pagamento para ver os demais dados e assim se precisar editá-los
            </span>
          </v-col>
          <v-col
            cols="6"
            sm="6"
            class="py-0 pl-0">
            <v-autocomplete
              v-if="store_Empreendimento.acao == 'C'"
              v-model="selected"
              label="Selecionar plano"
              class=""
              filled
              outlined
              dense
              background-color="#FFF"
              :items="store_Empreendimento.empreendimento_edicao.planos"
              item-text = "planovenda_nome"
              item-value = "cod_planovenda"
              return-object />
          </v-col>
          <!-- FIM PRIMEIRA COLUNA / SITUACAO ------------------------>
        </v-row>

        <v-row v-if="!!selected.cod_planovenda || !!selected.cod_empreendimento || store_Empreendimento.acao == 'I'">
          <v-col cols="12" class="pa-0">
            <v-card >
              <v-card-title 
								class="font-primary font-weight-bold font-weight-medium body-1 d-flex justify-space-between" 
								:style="{ 'color':COR_SUBTITULO }">
                 <span>Dados Básicos</span>

                  <div>
                    <v-btn
                      v-if="(store_Empreendimento.acao == 'C') && (Object.keys(selected).length != 0 )"
                      @click="planoAux()"
                      class="btn mr-1 pr-6 "
                      elevation="0">
                      <v-icon
                        class="btn-icon mr-1"
                        color="green accent-4">
                        mdi-pencil-outline
                      </v-icon>
                      <span
                        class="caption font-weight-medium"
                        v-bind:style="{ 'color':COR_SUBTITULO }">
                        Editar
                      </span>
                    </v-btn>
                    <v-btn
                      v-if="(store_Empreendimento.acao == 'C') && (Object.keys(selected).length != 0 )"
                      @click="dialogExcluir()"
                      class="btn mr-1 pr-6 "
                      elevation="0">
                      <v-icon
                        class="btn-icon mr-1"
                        color="red accent-4">
                        mdi-delete-outline
                      </v-icon>
                      <span
                        class="caption font-weight-medium"
                        v-bind:style="{ 'color':COR_SUBTITULO }">
                        Deletar
                      </span>
                    </v-btn>
                  </div>
              </v-card-title>
              <v-card-text>
                <v-row class="mt-1">
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-2 px-2 pb-0">
                    <v-text-field
                      v-model="selected.planovenda_nome"
                      background-color="#FFF"
                      :readonly="store_Empreendimento.acao == 'C'"
                      label="Descrição do plano:"
                      placeholder="Descrição do plano:"
                      :rules="nomeRules"
                      light
                      filled
                      outlined
                      required
                      dense ></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-0">
                    <span>
                      Digite o nome da descrição do plano. Exemplo: "À VISTA", "PARCELAMENTO", etc.
                    </span>
                  </v-col>
                </v-row>
                
                <v-row class="mt-1 pt-7" >
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-2 px-2 pb-0">
                    <v-select 
                      v-model="selected.visualiza_na_proposta" 
                      class="mt-n2 mb-0" 
                      background-color="#FFF" 
                      :readonly="store_Empreendimento.acao == 'C'" 
                      label="Visualiza na proposta:" 
                      placeholder="Visualiza na proposta:" 
                      auto-select-first 
                      light 
                      filled 
                      outlined  
                      dense
                      :items="array_sim_nao">
                    </v-select>
                  </v-col>
                  <v-col    
                    cols="6"
                    sm="6"
                    class="pt-0">
                    <span>
                      Este plano deve ser visualizado na proposta?
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-1 pt-4" >
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-7 px-2 pb-0">
                    <v-text-field 
                      v-model="selected.fase" 
                      class="mt-n2 mb-0" 
                      background-color="#FFF" 
                      label="Fase:" 
                      :readonly="store_Empreendimento.acao == 'C'" 
                      placeholder="Fase:" 
                      light 
                      filled 
                      outlined 
                      dense />
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-4">
                    <span>
                      Digite a fase de venda de acordo com a mesma fase do imóvel
                    </span>
                  </v-col>
								</v-row>
                <v-row class="mt-1 pt-7" >
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-2 px-2 pb-0">
										<v-select 
                      v-model="selected.planovenda_financiamento" 
                      class="mt-n2 mb-0" 
                      background-color="#FFF" 
                      :readonly="store_Empreendimento.acao == 'C'" 
                      label="Financiamento:" 
                      placeholder="Financiamento:" 
                      auto-select-first 
                      light 
                      filled 
                      outlined 
                      :rules="aceitaFinanciamentoRules"
                      required 
                      dense
                      :items="array_sim_nao">
                    </v-select>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-0">
                    <span>
                      Escolha se aceita financiamento
                    </span>
                  </v-col>
								</v-row>
                <v-row class="mt-1 pt-7" >
									<v-col
										cols="6"
										sm="6"
										class="pt-2 px-2 pb-0">
										<v-textarea 
											v-model="selected.planovenda_mensagem" 
											rows="1" 
											auto-grow 
											counter="255" 
											:readonly="store_Empreendimento.acao == 'C'" 
											class="input-razao mb-0" 
											loader-height="1" 
											background-color="#FFF" 
											label="Mensagem:" 
											placeholder="Mensagem:" 
											light 
											filled 
											outlined 
											required 
											dense />
									</v-col>
									<v-col
                    cols="6"
                    sm="6"
                    class="pt-0">
                    <span>
                      Digite a mensagem do plano
                    </span>
                  </v-col>
								</v-row>
								
              </v-card-text>

							<v-card-title
								class="font-primary font-weight-bold font-weight-medium body-1 pt-4 mb-4" 
								:style="{ 'color':COR_SUBTITULO }">
                  Dias de vencimento de entrada da parcela
              </v-card-title>
              <v-card-text>
								<v-row>
									<v-col
										cols="6"
										sm="6"
										class="pt-2 px-2 pb-0">
                    <v-text-field 
                      v-model="selected.plano_venda_1_opcaodiavencto" 
                      class="mt-n2 mb-0" 
                      background-color="#FFF" 
                      label="Opção de vencimento 1"
											placeholder="dia/mês/ano"
                      :readonly="store_Empreendimento.acao == 'C'" 
                      v-mask="['##/##/####']"
                      light 
                      filled 
                      :rules="divVenctoRules"
                      outlined 
                      
                      dense />
                  </v-col>
									<v-col
										cols="6"
										sm="6"
										class="pt-2 px-2 pb-0">
                    <v-text-field 
                      v-model="selected.plano_venda_2_opcaodiavencto" 
                      class="mt-n2 mb-0" 
                      background-color="#FFF" 
                      label="Opção de vencimento 2"
											placeholder="dia/mês/ano"
                      :readonly="store_Empreendimento.acao == 'C'" 
                      :rules="divVenctoRules"
                      v-mask="['##/##/####']"
                      light 
                      
                      filled 
                      outlined 
                      dense />
                  </v-col> 
								</v-row>
								<v-row
                  class="mt-1">
									<v-col
										cols="6"
										sm="6"
										class="pt-2 px-2 pb-0">
                    <v-text-field 
                      v-model="selected.plano_venda_3_opcaodiavencto" 
                      class="mt-n2 mb-0" 
                      background-color="#FFF" 
                      label="Opção de vencimento 3"
                      :rules="divVenctoRules"
											placeholder="dia/mês/ano"
                      :readonly="store_Empreendimento.acao == 'C'" 
                      v-mask="['##/##/####']"
                      
                      light 
                      filled 
                      outlined 
                      dense />
                  </v-col>
									<v-col
										cols="6"
										sm="6"
										class="pt-2 px-2 pb-0">
                    <v-text-field 
                      v-model="selected.plano_venda_4_opcaodiavencto" 
                      class="mt-n2 mb-0" 
                      background-color="#FFF" 
                      label="Opção de vencimento 4"
                      :rules="divVenctoRules"
											placeholder="dia/mês/ano"
                      :readonly="store_Empreendimento.acao == 'C'" 
                      v-mask="['##/##/####']"
                      light 
                      
                      filled 
                      outlined 
                      dense />
                  </v-col> 
								</v-row>
								<v-row
                  class="mt-1">
									<v-col
										cols="6"
										sm="6"
										class="pt-2 px-2 pb-0">
                    <v-text-field 
                      v-model="selected.plano_venda_5_opcaodiavencto" 
                      class="mt-n2 mb-0" 
                      background-color="#FFF" 
                      label="Opção de vencimento 5"
											placeholder="dia/mês/ano"
                      :rules="divVenctoRules"
                      :readonly="store_Empreendimento.acao == 'C'" 
                      v-mask="['##/##/####']"
                      
                      light 
                      filled 
                      outlined 
                      dense />
                  </v-col>
									<v-col
                    cols="6"
                    sm="6"
                    class="pt-0">
                    <span>
                      Existe um padrão de datas a serem seguidos no sistema, mas poderão ser sobrescritos caso algumas destas datas estejam preenchidas
                    </span>
                  </v-col>
								</v-row>
							</v-card-text>
              <v-card-title
								class="font-primary font-weight-bold font-weight-medium body-1 pt-0" 
								:style="{ 'color':COR_SUBTITULO }">
                  Entrada
              </v-card-title>
              <v-card-text>
                <v-row
                  class="mt-1"
                  >
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-2 px-2 pb-0">
                    <v-text-field
                      v-model="selected.planovenda_entrada_porc"
                      class="mt-n2 mb-0"
                      background-color="#FFF"
                      :readonly="store_Empreendimento.acao == 'C'"
                      label="Entrada min (%):"
                      placeholder="###.####"
                      :rules="entradaRules"
                      light
                      type="number"
                      
                      filled
                      outlined
                      required
                      dense>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-0">
                    <span>
                      Digite a entrada mínima que pode ser permitida para este plano de pagamento
                    </span>
                  </v-col>
                  </v-row>
                  <v-row
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-select 
                        v-model="selected.planovenda_entrada_sinal" 
                        class="mt-n2 mb-0" 
                        background-color="#FFF" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        label="Permite sinal:" 
                        placeholder="Permite sinal:" 
                        :rules="permiteSinalRules" 
                        auto-select-first 
                        light 
                        filled 
                        outlined 
                        required 
                        dense
                        :items="array_sim_nao">
                      </v-select>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Escolha se o plano exige sinal
                      </span>
                    </v-col>
							    </v-row>
                  <v-row
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-select
                        v-model="selected.planovenda_entrada_sinal_obr" 
                        class="mt-n2 mb-0" 
                        background-color="#FFF" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        label="Sinal obrigatório:" 
                        placeholder="Sinal obrigatório:" 
                        :rules="sinalObrigatorioRules" 
                        auto-select-first 
                        light 
                        filled 
                        outlined 
                        required 
                        dense
                        :items="array_sim_nao">
                      </v-select>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Escolha se o sinal é obrigatório
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-select
                        v-model="selected.planovenda_juros_tipo" 
                        class="mt-n2 mb-0" 
                        background-color="#FFF" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        label="Tipo de cálculo de juros:" 
                        placeholder="Tipo de cálculo de juros:"  
                        auto-select-first 
                        light 
                        filled 
                        outlined  
                        dense
                        :items="tipo_calculo">
                      </v-select>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Escolha o tipo de cálculo que deve ser executado para este plano de pagamento
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-autocomplete 
                        v-model="selected.planovenda_entrada_quant" 
                        class="mt-n2 mb-0" 
                        background-color="#FFF" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        label="Forma de pagamento:" 
                        placeholder="Forma de pagamento:"
                        auto-select-first 
                        light 
                        filled 
                        outlined 
                        required 
                        dense
                        :rules="entradaQuantRules"
                        item-text = "value"
                        item-value = "id"
                        :items="array_planovenda_entrada_quant">
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Escolha a forma de pagamento para este plano de pagamento
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="selected.planovenda_entrada_quant != 1"
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-select 
                        v-model="selected.planovenda_entrada_reaj_parc" 
                        class="mt-n2 mb-0" 
                        background-color="#FFF" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        label="Aplica no saldo:" 
                        placeholder="Aplica no saldo:" 
                        :rules="aplicaNoSaldoRules" 
                        auto-select-first 
                        light 
                        filled 
                        outlined 
                        required 
                        dense
                        :items="array_sim_nao">
                      </v-select>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Escolha se o reajuste aplica no saldo
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="selected.planovenda_entrada_quant != 1"
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-text-field
                        v-model="selected.planovenda_entrada_reajuste"
                        class="mt-n2 mb-0"
                        background-color="#FFF"
                        :readonly="store_Empreendimento.acao == 'C'"
                        label="Reajuste (%):"
                        placeholder="Reajuste (%):"
                        :rules="reajusteRules"
                        light
                        
                        type="number"
                        filled
                        outlined
                        required
                        dense>
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Digite a porcentagem de reajuste
                      </span>
                    </v-col>
                </v-row>
                
                <v-row
                  class="mt-1 pt-7"
                  >
									<v-col
										cols="6"
										sm="6"
										class="pt-2 px-2 pb-0">
										<v-select
											v-model="selected.planovenda_entrada_sinal_altera" 
											class="mt-n2 mb-0" 
											background-color="#FFF" 
											:readonly="store_Empreendimento.acao == 'C'" 
											label="Permite alteração sinal:" 
											placeholder="Permite alteração sinal:" 
											:rules="permiteAlteracaoSinalRules" 
											auto-select-first 
											light 
											filled 
											outlined 
											required 
											dense
											:items="array_sim_nao">
										</v-select>
									</v-col>
									<v-col
										cols="6"
										sm="6"
										class="pt-0">
										<span>
											Escolha se a alteração do sinal é permitida pelo usuário/corretor que está criando a proposta
										</span>
									</v-col>
								</v-row>
                <v-row
                  class="mt-1 pt-7"
                  >
									<v-col
										cols="6"
										sm="6"
										class="pt-2 px-2 pb-0">
										<v-select
											v-model="selected.planovenda_entrada_sinal_min" 
											class="mt-n2 mb-0" 
											background-color="#FFF" 
											:readonly="store_Empreendimento.acao == 'C'" 
											label="Mínimo sinal permitido:" 
											placeholder="Mínimo sinal permitido:" 
											auto-select-first 
											light 
											filled 
											outlined 
											required 
											dense
											:items="array_sim_nao">
										</v-select>
									</v-col>
									<v-col
										cols="6"
										sm="6"
										class="pt-0">
										<span>
											Escolha se a entrada mínima do sinal é obrigatória, 
											caso o campo estiver como "SIM" e a proposta estiver sendo feita 
											com um valor menor que o permitido no plano de pagamento o sistema não irá permitir
										</span>
									</v-col>
								</v-row>
								
                <v-row
                  class="mt-1 pt-7"
                  >
									<v-col
											cols="6"
											sm="6"
											class="pt-2 px-2 pb-0">
											<v-text-field
												v-model="selected.planovenda_entrada_valor_min"
												class="mt-n2 mb-0"
												background-color="#FFF"
												:readonly="store_Empreendimento.acao == 'C'"
												label="Valor mínimo de entrada:"
												placeholder="Valor mínimo de entrada:"
												light
                        :maxlength="5"
												type="number"
												filled
                        
												outlined
												required
												dense>
											</v-text-field>
										</v-col>
										<v-col
											cols="6"
											sm="6"
											class="pt-0">
											<span>
												Digite o valor mínimo de entrada
											</span>
										</v-col>
								</v-row>
              </v-card-text>
              <div v-if="!/100/.test(selected.planovenda_entrada_porc)">
                <v-card-title
                class="font-primary font-weight-bold font-weight-medium body-1 pt-0" 
                  :style="{ 'color':COR_SUBTITULO }">
                    Parcelas
                </v-card-title>
                <v-card-text>
                  <v-row
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-text-field 
                        v-model="selected.planovenda_parcela_quant" 
                        class="mt-n2 mb-0" 
                        background-color="#FFF" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        label="Máximo de parcelas:" 
                        placeholder="Máximo de parcelas:" 
                        :rules="maximoParcelasRules" 
                        light 
                        type="number"
                        filled 
                        outlined 
                        required 
                        dense />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Digite o máximo de parcelas para este plano de pagamento
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-text-field 
                        v-model="selected.planovenda_parcela_valor_min" 
                        class="mt-n2 mb-0" 
                        background-color="#FFF" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        label="Valor mínimo de parcelas:" 
                        placeholder="Valor mínimo de parcelas:" 
                        
                        light 
                        type="number"
                        filled 
                        outlined 
                        required 
                        dense />
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Digite o valor mínimo para parcelas deste plano de pagamento
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-text-field 
                        class="mt-n2 mb-0" 
                        background-color="#FFF" 
                        v-model="selected.planovenda_parcela_reajuste" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        label="Juros mensal:" 
                        placeholder="Juros mensal:" 
                        :rules="jurosMensalRules" 
                        light 
                        type="number"
                        
                        filled 
                        outlined  
                        dense >
                      </v-text-field>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Digite quais serão os juros mensais para este plano de pagamento
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-1 pt-7">
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-2 px-2 pb-0">
                      <v-select 
                        v-model="selected.planovenda_parcela_saldo_tipo" 
                        class="mt-n2 mb-0" 
                        background-color="#FFF" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        label="Tipo de juros para o saldo:" 
                        placeholder="Tipo de juros para o saldo:" 
                        auto-select-first 
                        light 
                        filled 
                        outlined 
                        required 
                        dense
                        :items="array_saldo_tipo">
                      </v-select>
                  
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="pt-0">
                      <span>
                        Escolha se os juros do sinal serão transferidos para as parcelas
                      </span>
                    </v-col>
                  </v-row>
                  <v-row
                    class="mt-1 pt-7"
                    >
                    <v-col
                      cols="12"
                      sm="12"
                      class="pt-2 px-2 pb-0">
                      <v-textarea 
                        v-model="selected.planovenda_obs" 
                        rows="1" 
                        auto-grow 
                        counter="150" 
                        :readonly="store_Empreendimento.acao == 'C'" 
                        class="input-razao mb-0" 
                        loader-height="1" 
                        background-color="#FFF" 
                        label="Observações:" 
                        placeholder="Observações:" 
                        light 
                        filled 
                        outlined 
                        required 
                        dense />
                    </v-col>
                  </v-row>
                  
                </v-card-text>
              </div>
            </v-card>

          </v-col>
        </v-row>
        

        <v-footer v-if="store_Empreendimento.acao != 'C'" 
          color="transparent" 
          elevation="0" 
          class="footer justify-center mt-3 pt-3">
            <v-btn @click="cancelar()" 
              id="btn_cancelar" 
              ref="btn_cancelar" 
              class="mr-4 caption font-weight-medium" 
              color="primary" 
              text>
            Cancelar
          </v-btn>

          <v-btn
            :loading="loading"
            :disabled="!valid"
            @click="validate()"
            class="btn caption font-weight-medium white--text"
            color="primary accent-4">
            Salvar
          </v-btn>
        </v-footer>

      </v-container>  
    </v-form>
    <!--------- DIALOG EXCLUIR EMPREENDIMENTO ------------------------------->
  <v-container v-if="dialog_excluir">
    <v-dialog v-model="dialog_excluir" transition="dialog-bottom-transition" persistent max-width="410" class="pa-0">
      <v-divider></v-divider>

      <v-card elevation="0" class="">
        <v-card-title class="text-h6" style="word-break: keep-all">
          Tem certeza que deseja EXCLUIR este plano de pagamento?
        </v-card-title>
        <v-card-text>
          <br>
          <span style="font-size: 15px; font-weight:600">Nome: {{ selected.planovenda_nome }}</span><br>
        
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-spacer></v-spacer>
          <v-btn id="btn_cancelar" ref="btn_cancelar" class="mr-4" color="primary" text @click="dialog_excluir = false">
            Cancelar
          </v-btn>

          <v-btn class="btn white--text" color="primary accent-4" :loading="loading_excluir" @click="excluir()">
            Excluir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

  </div>
</template>
  
<script>
import { COR_SUBTITULO } from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";
import store_site from "../../../store/store_site";
import { API } from "../../../services/API"
import { formatNumber } from "../../../services/funcoes";
import moment from 'moment'

export default {
  name: "PlanosPagamento",
  components: {
  },

  data() {
    return {
     
      store_Empreendimento      : store_Empreendimento,
      store_site                : store_site,
      loading                   : false,
      COR_SUBTITULO             : COR_SUBTITULO,  
      edicao_aux                : null,
      planos                    : {},
      selected                  : {},
      valid                     : true,
      formatNumber              : formatNumber,
      array_planovenda_entrada_quant: null,
      dialog_excluir            : false,
      loading_excluir           : false,
      
			array_saldo_tipo : [
				"VALOR FUTURO DE INVESTIMENTO FINANCEIRO",
				'NENHUM'
			],
      array_sim_nao: [
        "SIM", "NÃO",
      ],
      tipo_calculo:[
        "JUROS COMPOSTOS", "TABELA PRICE",
      ],

      forma_pagamento:[
        "Tipo1", "Tipo2", "Tipo3",
      ],
      forma_pagamento_aux:"Tipo1",
      aplica_saldo:[
        "Sim", "Nao"
      ],
      aplica_saldo_aux: "Sim",

      nomeRules: [
        (value) => !!value || "O nome é obrigatório",
        (value) =>
          (value && value.length <= 60) ||
          "O nome deve ter menos do que 60 caracteres",
      ],
      entradaRules: [
        (value) => !!value || "A entrada é obrigatória",
        (value) => /^(100(\.0{1,4})?|[1-9][0-9]{0,1}(\.[0-9]{1,4})?)$/.test(value) || 'A porcentagem não é válida',
        // (value) =>
          // (value && Number(value) > 0) ||
          // "A entrada deve ser pelo menos 1%",
        // (value) =>
          // (value && Number(value) <= 100) ||
          // "A entrada deve ser no máximo 100%",
      ],
      valorMinParcelasRules: [
        (value) => !!value || "O valor mínimo de parcelas é obrigatório",
        (value) =>
          (value && Number(value) > 0) ||
          "O valor mínimo de parcelas deve ser pelo menos 1",
          (value) =>
          (value && value.length <= 17) ||
          "O valor mínimo de parcelas aceita no máximo um número de 15 caractéres e 2 casas decimais",
      ],
      valorMinRules:[
      (value) => !!value || "O valor mínimo de entrada é obrigatório",
        (value) =>
          (value && Number(value) > 0) ||
          "O valor mínimo de entrada deve ser pelo menos 1",
        (value) =>
          (value && value.length <= 17) ||
          "O valor mínimo de entrada aceita no máximo um número de 15 caractéres e 2 casas decimais" + value + ' ffff',
      ],
      reajusteRules:[
        (value) => !!value || "O Reajuste é obrigatório",
        (value) => /^(100(\.0{1,4})?|[1-9][0-9]{0,1}(\.[0-9]{1,4})?)$/.test(value) || 'A porcentagem não é válida',
        // (value) =>
        //   (value && Number(value) > 0) || "A entrada deve ser pelo menos 1%",
        // (value) =>
        //   (value && Number(value) <= 100) || "A entrada deve ser no máximo 100%",
      ],
      jurosMensalRules: [
        (value) => !!value || "O campo Juros Mensal é obrigatório",
        (value) => /^(100(\.0{1,4})?|[1-9][0-9]{0,1}(\.[0-9]{1,4})?)$/.test(value) || 'A porcentagem não é válida',

        // (value) =>
        //   (value && Number(value) > 0) ||
        //   "Valor deve ser pelo menos 1",
        // (value) =>
        //   (value && Number(value) <= 100) ||
        //   "Valor deve ser no máximo 100",
      ],
      entradaQuantRules:[
        (value) => !!value || "A forma de pagamento é obrigatória",
      ],
      aceitaFinanciamentoRules: [
        (value) => !!value || "O financiamento é obrigatório",
      ],
      divVenctoRules: [
        value => {
          if (!value) return true; // se o campo estiver vazio, a validação é ignorada
          const isValid = moment(value, this.$vuetify.lang.current === 'pt' ? 'DD/MM/YYYY' : 'YYYY-MM-DD', true).isValid();
          return isValid || 'A data é inválida';
        }
    ],
			data_picker : {
        'min-width':'250px !important',
				// 'width' : '290px !important',
				'top' : '369px !important',
				'left': '58px',
    		'transform-origin': 'left top',
    		'z-index': '10'

        //'max-width':'180px !important',
        //'margin-right':'8px !important',
        //'margin-top':'-15px !important',
        // 'background':'red'
      },

      permiteSinalRules: [
        (value) => !!value || "Permite sinal é obrigatório",
      ],
      sinalObrigatorioRules: [
        (value) => !!value || "Sinal obrigatório é obrigatório",
      ],      
      permiteAlteracaoSinalRules: [
        (value) => !!value || "Permite alteração de sinal é obrigatório",
      ],      
      aplicaNoSaldoRules: [
        (value) => !!value || "Aplica no saldo é obrigatório",
      ],
      maximoParcelasRules: [
        (value) => /^\d+$/.test(value) || 'O valor não é válido',
        (value) => !!value || "Máximo de parcelas é obrigatório",
      ],
     
      
    };
  },

  methods: {
    async excluir() {
      this.loading_excluir = true;

      const ls_Resp = await this.store_Empreendimento.PlanosPagamentoDelete({
        cod_planovenda: this.selected.cod_planovenda
      });

      console.log('ls_Resp', ls_Resp)

      if (ls_Resp.message == "success") {
        this.store_site.alert_cor = "green";
        this.store_site.alert_timeout = 1500;
        this.store_site.alert_type = "success";
        this.store_site.alert_msg = ls_Resp.result;
        this.store_site.alert = true;

        const i = this.store_Empreendimento.empreendimento_edicao.planos.findIndex(element => element.cod_planovenda == this.selected.cod_planovenda)
        if (i !== -1) {
          this.store_Empreendimento.empreendimento_edicao.planos.splice(i, 1)
        }
        this.selected = {}
      } else {
        this.store_site.alert_timeout = 20000;
        this.store_site.alert_cor = "red";
        this.store_site.alert_type = "warning";
        this.store_site.alert_msg = 'Erro ao excluir! Provavelmente este plano de pagamento deve ter sido utilizado.';
        this.store_site.alert_timeout = 20000;
        console.log('errors:',ls_Resp.errors)
        this.store_site.alert = true;
      }
      this.loading_excluir = false;
      this.dialog_excluir = false;
    },

    dialogExcluir(){
      this.dialog_excluir = true
    },
    novo(){
      this.selected = {}
      this.store_Empreendimento.acao = 'I'      
    },
    async getPlanovendaEntradaQuant(){
      const ls_Rel = await API.get(`configuracao/config_principal`);

      let quant = ls_Rel.data.result[0].planovenda_maximo_parcela_entradas

      let counteudo_array = [{
        id: 1, value: 'À VISTA'
      }];

      for(let i = 2; i <= quant; i++){

        if(i >=10) {
          counteudo_array[i] =  `ATÉ ${i} VEZES`
        }else{
          counteudo_array[i] =  {id: i, value: `ATÉ 0${i} VEZES`}
        }
      }
      this.array_planovenda_entrada_quant = counteudo_array;
    },


    async validate() {
      const lb_valido = this.$refs.form.validate();
      if (lb_valido){
        this.planos.cod_empreendimento                 = this.$route.params.cod_empreendimento;
        this.planos.cod_planovenda                     = this.selected.cod_planovenda;
        this.planos.planovenda_nome                    = this.selected.planovenda_nome;
        this.planos.planovenda_entrada_porc            = this.selected.planovenda_entrada_porc;
        this.planos.planovenda_entrada_quant           = this.selected.planovenda_entrada_quant;
        this.planos.planovenda_entrada_sinal           = this.selected.planovenda_entrada_sinal;
        this.planos.planovenda_entrada_reajuste        = this.selected.planovenda_entrada_quant != 1 ? this.selected.planovenda_entrada_reajuste: 0
        console.log("🚀 ~ file: PlanosPagamento.vue:1088 ~ validate ~ this.selected.planovenda_entrada_quant:", this.selected.planovenda_entrada_quant)
        this.planos.planovenda_entrada_reaj_parc       = this.selected.planovenda_entrada_quant != 1 ? this.selected.planovenda_entrada_reaj_parc : ''
        this.planos.planovenda_entrada_sinal_obr       = this.selected.planovenda_entrada_sinal_obr;
        this.planos.planovenda_entrada_sinal_altera    = this.selected.planovenda_entrada_sinal_altera;
        this.planos.planovenda_entrada_sinal_min       = this.selected.planovenda_entrada_sinal_min;
        this.planos.planovenda_entrada_valor_min       = this.selected.planovenda_entrada_valor_min;
        this.planos.planovenda_parcela_quant           = /100/.test(this.selected.planovenda_entrada_porc) ? 0 :  this.selected.planovenda_parcela_quant; //PARCELA
        this.planos.planovenda_parcela_reajuste        = /100/.test(this.selected.planovenda_entrada_porc) ? 0 :  this.selected.planovenda_parcela_reajuste; // PARCELA
        this.planos.planovenda_parcela_valor_min       = /100/.test(this.selected.planovenda_entrada_porc) ? null : this.selected.planovenda_parcela_valor_min; //PARCELA
        this.planos.planovenda_parcela_saldo_tipo      = /100/.test(this.selected.planovenda_entrada_porc) ? null : this.selected.planovenda_parcela_saldo_tipo; //PARCELA
        this.planos.planovenda_obs                     = this.selected.planovenda_obs;
        this.planos.planovenda_financiamento           = this.selected.planovenda_financiamento;
        this.planos.planovenda_juros_tipo              = this.selected.planovenda_juros_tipo;
        this.planos.planovenda_mensagem                = this.selected.planovenda_mensagem;
        this.planos.visualiza_na_proposta              = this.selected.visualiza_na_proposta;
        this.planos.fase                               = this.selected.fase;
        this.planos.planovenda_formula                 = this.selected.planovenda_formula,
        this.planos.plano_venda_1_opcaodiavencto       = this.selected.plano_venda_1_opcaodiavencto
        this.planos.plano_venda_2_opcaodiavencto       = this.selected.plano_venda_2_opcaodiavencto
        this.planos.plano_venda_3_opcaodiavencto       = this.selected.plano_venda_3_opcaodiavencto
        this.planos.plano_venda_4_opcaodiavencto       = this.selected.plano_venda_4_opcaodiavencto
        this.planos.plano_venda_5_opcaodiavencto       = this.selected.plano_venda_5_opcaodiavencto

        // console.log("🚀 ~ file: PlanosPagamento.vue:1036 ~ validate ~ this.planos:", this.planos)
        // return

        var lo_JSON = {
          ...this.planos,
        };
      
        var ls_Res;
        this.loading = true;
        if(this.store_Empreendimento.acao === 'E'){
          console.log('editar');
          ls_Res = await this.store_Empreendimento.PlanosPagamentoPut(lo_JSON);

          if (ls_Res.result.message == 'success') {
            // console.log('store', this.store_Empreendimento.empreendimento_edicao.planos[0]);
            // let indice_para_atualizar = this.store_Empreendimento.empreendimento_edicao.planos.findIndex((o) => o.cod_planovenda = this.selected.cod_planovenda)
            // console.log("🚀 ~ indice_para_atualizar:", indice_para_atualizar)
            // if(indice_para_atualizar !== -1){
            //   // this.store_Empreendimento.empreendimento_edicao.planos[indice_para_atualizar] = this.selected
            //   console.log("🚀 ~2222", this.store_Empreendimento.empreendimento_edicao.planos[indice_para_atualizar])
            //   console.log("🚀 ~3333", this.selected)
            // }
            // console.log("🚀 ~ editar para atualizar:", indice_para_atualizar)
          }
        }
        else{
          console.log('inserir',lo_JSON);
          ls_Res = await this.store_Empreendimento.PlanosPagamentoPost(lo_JSON);

          if (ls_Res.result.message == 'success') {
            this.store_Empreendimento.empreendimento_edicao.planos.push(ls_Res.result.data)
          }
        }
        // return
        
        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          
          this.store_Empreendimento.acao = "C";
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
      }
    },

    cancelar(){
      if (this.store_Empreendimento.acao != 'I') {
        const lAux = this.store_Empreendimento.empreendimento_edicao.planos.findIndex((o) => o.cod_planovenda = this.edicao_aux)
        this.store_Empreendimento.empreendimento_edicao.planos[lAux] = this.edicao_aux;
        this.selected = this.store_Empreendimento.empreendimento_edicao.planos[lAux];
        
      }

      this.store_Empreendimento.acao = 'C';
    },

    async planoAux(){
      this.store_Empreendimento.acao = 'E'; 
      this.edicao_aux = JSON.parse(JSON.stringify(this.selected));
    }
  },

  async mounted(){
    this.getPlanovendaEntradaQuant()
  }

};
</script>

<style scoped>
#PlanosPagamento {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
  padding-top: 45px;
  padding-right: 8px;
  width: 100%;
}

@media(max-width: 599px) {
  #PlanosPagamento {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#PlanosPagamento::-webkit-scrollbar {
  width: 5px;
}

#PlanosPagamento::-webkit-scrollbar-button {
  padding: 1px;
}

#PlanosPagamento::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#PlanosPagamento::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #PlanosPagamento::-webkit-scrollbar {
    width: initial;
  }

  #PlanosPagamento::-webkit-scrollbar-button {
    padding: initial;
  }

  #PlanosPagamento::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #PlanosPagamento::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
}

.nav-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.87);
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}

.theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle{
	color:rgba(0, 0, 0, 0.87)
}

.v-datatable__row--striped:nth-of-type(odd) {
  background-color: #f5f5f5; /* cor clara */
}
.v-datatable__row--striped:nth-of-type(even) {
  background-color: #eeeeee; /* cor escura */
}

</style>
