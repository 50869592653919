<template>
  <div id="Financeiro" class="px-2 pt-0">
    <v-tabs
      id="tabs"
      v-model="tab"
      background-color="transparent"
      slider-size="2"
      slider-top="10"
      tabindex="-1"
      next-icon="mdi-arrow-right"
      prev-icon="mdi-arrow-left"
      show-arrows
      active-class="active">
      <!-- Financeiro -->
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-4 "
        :style="{ 'background-color': tab == 0 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1"> 
        <p
          class="font-primary body-1 mt-2"
          :style="{ 'color': tab == 0 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 0 ? 'medium' : 'thin' }">
          Financeiro
        </p>
      </v-tab>

      <!-- Gerais -->
      <v-tab
        id="tab"
        class="text-white title-page text-none mt-3 ml-4 "
        v-bind:style="{ 'background-color': tab == 1 ? '#FFFFFF' : 'transparent', 'height': '40px' }"
        tabindex="-1">
        <p
          class="font-primary body-1 mt-2"
          v-bind:style="{ 'color': tab == 1 ? COR_SUBTITULO : '#A0A0A0', 'font-weight': tab == 1 ? 'medium' : 'thin' }">
          Gerais
        </p>
      </v-tab>
    </v-tabs>

    <!-- FINANCEIRO -->
    <div v-if="tab == 0">
      <v-row>
        <v-col cols="12">
          <v-card 
            class="pa-3"
            elevation="0">
              <!-- TÍTULO DA PÁGINA -->
            <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2">
              <p class="font-primary font-weight-bold font-weight-medium body-1" v-bind:style="{ 'color':COR_SUBTITULO }"></p>
              <v-btn
                v-if="store_Empreendimento.acao == 'C'"
                @click="store_Empreendimento.acao = 'E'; empreendimento_edicaoAux={...store_Empreendimento.empreendimento_edicao}"
                class="btn pr-6 "
                elevation="0">
                  <v-icon
                    class="btn-icon mr-1"
                    color="green accent-4">mdi-pencil-outline</v-icon>
                  <span
                    class="caption font-weight-medium"
                    v-bind:style="{ 'color':COR_SUBTITULO }">
                    Editar
                  </span>
              </v-btn>
            </v-toolbar-title>
              <!-- FIM TÍTULO DA PÁGINA -->
  
            <v-dialog
            v-model="store_Empreendimento.dialogFinanceiro"
            transition="dialog-bottom-transition"
            max-width="640"
            class="pa-0">
              <FinanceiroModal />
            </v-dialog>
  
              <!-------- CONTAINER "LABELS" ----------------------------->
            <v-form ref="form" class="mx-1 mt-3" lazy-validation>
              <v-container class="container pt-2 px-0">
                <v-row class="mt-n5">
                  <v-col cols="6" sm="6" class="pt-2 px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.cod_pessoa_conta"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="array_pessoa_conta_cedente"
                      label="Nome e conta do cedente"
                      item-value="cod_pessoa_conta"
                      item-text="nome"
                      :readonly="store_Empreendimento.acao == 'C'">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-2 px-2 pb-0">
                    <span>
                      Nome e conta do cedente é o dado que representa a conta de emissão de boletos e 
                      servirá de base para as operações bancárias deste empreendimento
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <v-text-field
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_local_pgto"
                      :rules="localRules"
                      :readonly="store_Empreendimento.acao == 'C'"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Local de pagamento"
                      placeholder="Local de pagamento"
                      light
                      filled
                      outlined
                      dense>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Local de pagamento indicado. Este campo será como por exemplo: 
                      Preferencialmente nas casas lotéricas até o valor limite.
                    </span>
                  </v-col>
                </v-row>

                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_instr1_ent"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_entrada_dt"
                      label="Entradas"
                      item-value="value"
                      item-text="name"
                      :readonly="store_Empreendimento.acao == 'C'">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Dias máximos para limites de pagamento de entradas. Este campo será visualizado como por exemplo: 
                      Sr(a) Caixa, não receber até N dias após o vencimento
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-textarea
                      rows="1"
                      auto-grow
                      counter="150"
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_instr2_ent"
                      :rules="instrucoesRules"
                      :readonly="store_Empreendimento.acao == 'C'"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Instruções entradas:"
                      placeholder="Instruções entradas:"
                      light
                      filled
                      outlined
                      dense></v-textarea>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      São as instruções de como deve ser a entrada. Por exemplo: 
                      ***MANTENHA SEU TERRENO LIMPO*** CERCADO OU MURADO***555
                    </span>
                  </v-col>
                </v-row>

                <v-row class="mt-0 pt-3"> 
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_instr1_parc"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_parcelas_dt"
                      label="Parcelas"
                      item-value="value"
                      item-text="name"
                      :readonly="store_Empreendimento.acao == 'C'">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Dias máximos para limites de pagamento de parcelas (em dias). Este campo será visualizado como por exemplo: 
                      Sr(a) Caixa, não receber até N dias após o vencimento
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-textarea
                      rows="1"
                      auto-grow
                      counter="150"
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_instr2_parc"
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="instrucoesRules"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Instruções parcelas:"
                      placeholder="Instruções parcelas:"
                      light
                      filled
                      outlined
                      required
                      dense>
                    </v-textarea>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Campo utilizado como mensagem nas impressões de boletos de entrada como por
                      exemplo: "Mantar seu terreno murado", "Receba desconto por pagar em dia", etc
                    </span>
                  </v-col>
                </v-row>

                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-text-field
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_convenio"
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="codigoRules"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Código cedente:"
                      placeholder="Código cedente:"
                      light
                      filled
                      outlined
                      dense></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Campo para indicar o código do cedente
                    </span>
                  </v-col>
                </v-row>  
                <v-row v-show="false" class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-text-field
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_cedente"
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="repetirCedenteRules"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Repetir cedente ou antigo:"
                      placeholder="Repetir cedente ou antigo:"
                      light
                      filled
                      outlined
                      dense></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Campo para repetir o código do cedente ou o código do cedente antigo
                    </span>
                  </v-col>
                </v-row>
        
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-text-field
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_carteira"
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="nomeRules"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Nome / tipo da carteira:"
                      placeholder="Nome / tipo da carteira:"
                      light
                      filled
                      outlined
                      dense></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Indique o nome e o tipo da carteira
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-text-field
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_multa"
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="porcentagemRules"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Multa(%):"
                      type="number"
                      placeholder="Multa(%):"
                      light
                      filled
                      outlined
                      dense></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Campo para indicar qual a porcentagem de multa após o vencimento
                    </span>
                  </v-col>
                </v-row>
      
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-text-field
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_juros"
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="porcentagemRules"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Juros(%):"
                      placeholder="Juros(%):"
                      light
                      type="number"
                      filled
                      outlined
                      dense></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Campo para indicar qual a porcentagem de juros diarios após o vencimento
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_registrado"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_boleto"
                      label="Boleto Registrado:"
                      item-text="name"
                      required
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="boletoRules">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Campo para indicar se o boleto é ou não registrado
                    </span>
                  </v-col>
                </v-row>
      
                <v-row class="mt-0 pt-3"> 
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_indice.cod_indice"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="array_select_indice"
                      label="Índice de Correção:"
                      item-value="cod_indice"
                      item-text="indice_nome"
                      :readonly="store_Empreendimento.acao == 'C'">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Selecione o indice de correção desejado
                    </span>
                  </v-col> 
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-text-field
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_indiceperiodo"
                      :readonly="store_Empreendimento.acao == 'C'"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Correções a cada:"
                      placeholder="Correções a cada:"
                      light
                      :rules="correcoesRules"
                      filled
                      outlined
                      maxlength="10"
                      dense>
                    </v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-0 px-2 pb-0">
                    <span>
                      Digite após quantos meses será feita a correção
                    </span>
                  </v-col>
                </v-row> 
      
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.cod_indice_acordo"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="array_select_indice"
                      label="Índice:"
                      item-value="cod_indice"
                      item-text="indice_nome"
                      :readonly="store_Empreendimento.acao == 'C'">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Selecione o índice de acordo jurídico
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_indice_entrada"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_contar_meses"
                      label="Contar meses?"
                      item-text="name"
                      required
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="contarMesesRules">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Selecione se os meses serão contados para a correção à partir da parcela de entrada
                    </span>
                  </v-col>
                </v-row>  
        
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_indice_entrada_sinal"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_contar_sinal"
                      label="Contar sinal?"
                      item-text="name"
                      required
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="contarSinalRules">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Selecione se será contado o sinal de entrada
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_boleto_baixar"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_gerar_remessa"
                      label="Gerar remessa?"
                      item-text="name"
                      :readonly="store_Empreendimento.acao == 'C'">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Selecione se vai ser gerado remessa com ordem de baixa após o vencimento do boleto
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.boleto_emissao"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_emitir"
                      label="Emitir boleto?"
                      item-text="name"
                      item-value="value"
                      :readonly="store_Empreendimento.acao == 'C'">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Selecione se o banco vai emitir o boleto
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.boleto_distribuicao"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_distribuir"
                      label="Distribuir boleto?"
                      item-text="name"
                      item-value="value"
                      :readonly="store_Empreendimento.acao == 'C'">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Selecione se o banco vai distribuir o boleto
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.indice_proporcional_antecipacao"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_gerar_remessa"
                      label="Indice proporcional de antecipação?"
                      item-text="name"
                      required
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="indiceProporcionalRules">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Selecione se irá ter indice proporcional de antecipação
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-autocomplete
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_aplica_reajuste_entr"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_gerar_remessa"
                      label="Aplica reajuste de entrada?"
                      item-text="name"
                      :readonly="store_Empreendimento.acao == 'C'">
                    </v-autocomplete>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Selecione se irá aplicar reajuste de entrada
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-text-field
                      v-model="store_Empreendimento.empreendimento_edicao.quant_meses_indice_reajuste"
                      :readonly="store_Empreendimento.acao == 'C'"
                      class="input-razao mb-0"
                      loader-height="1"
                      background-color="#FFF"
                      label="Quant. de meses do indice de reajuste:"
                      placeholder="Quant. de meses do indice de reajuste:"
                      light
                      type="number"
                      filled
                      outlined
                      dense></v-text-field>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Campo para indicar quantos meses para aplicar o indice de reajuste
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-select
                      v-model="store_Empreendimento.empreendimento_edicao.vcto_dia_util_anterior"
                      auto-select-first  
                      filled 
                      outlined 
                      dense 
                      background-color="map-deep-merge white" 
                      :items="opcoes_gerar_remessa"
                      label="Dia de vencimento"
                      item-text="name"
                      required
                      :readonly="store_Empreendimento.acao == 'C'"
                      :rules="diaDeVencimentoRules">
                    </v-select>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="px-2 pb-0">
                    <span>
                      Campo que indica se a data de vencimento da parcela será gerada baseada no dia útil 
                      anterior (considerando finais de semana e feriados) ou no somente no dia em que foi 
                      indicado (Ex: todos dia 20) independente se cair no feriado ou final de semana.
                    </span>
                    <br>
                  
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                    <v-col cols="6" sm="6" class="px-2 pb-0">
                      <v-autocomplete
                        v-model="store_Empreendimento.empreendimento_edicao.empreend_mostra_preco_unidades"
                        auto-select-first  
                        filled 
                        outlined 
                        dense 
                        background-color="map-deep-merge white" 
                        :items="opcoes_gerar_remessa"
                        label="Mostra preço das unidades?"
                        item-text="name"
                        required
                        :readonly="store_Empreendimento.acao == 'C'">
                      </v-autocomplete>
                    </v-col>
                    <v-col
                      cols="6"
                      sm="6"
                      class="px-2 pb-0">
                      <span>
                        Selecione para saber se deve mostrar o preço das unidades
                      </span>
                    </v-col>
                  </v-row>
              </v-container>  
            </v-form>
          <!-------- FIM CONTAINER "LABELS" ----------------------------->
          </v-card>
        </v-col>
      </v-row>

      <!-- botoes do rodape de Salvar e Cancelar -->
      <v-footer
        v-if="store_Empreendimento.acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center pt-3">
        <v-btn
          @click="cancelar()"
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text>
          Cancelar
        </v-btn>
  
        <v-btn
          :loading="loading"
          @click="validate()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>
    </div>
    <!-- FIM FINANCEIRO -->

    <!-- Gerais -->
    <div v-if="tab == 1">
      <!-- <pre>{{ store_Empreendimento.empreendimento_edicao }}</pre> -->
      <v-row>
        <v-col cols="12">
          <v-card 
            class="pt-2 pa-2"
            elevation="0">
            <!-- TÍTULO DA PÁGINA -->
            <v-toolbar-title v-bind:style="{ 'color':COR_SUBTITULO }" class="d-flex align-center justify-space-between body-1 font-primary ml-2">
              <p class="font-primary font-weight-bold font-weight-medium body-1" v-bind:style="{ 'color':COR_SUBTITULO }">Institucional do Empreendimento</p>
              <v-btn
                v-if="store_Empreendimento.acao == 'C'"
                @click="store_Empreendimento.acao = 'E'; empreendimento_edicaoAux={...store_Empreendimento.empreendimento_edicao}"
                class="btn pr-6 "
                elevation="0">
                  <v-icon
                    class="btn-icon mr-1"
                    color="green accent-4">mdi-pencil-outline</v-icon>
                  <span
                    class="caption font-weight-medium"
                    v-bind:style="{ 'color':COR_SUBTITULO }">
                    Editar
                  </span>
              </v-btn>
            </v-toolbar-title>
            <!-- FIM TÍTULO DA PÁGINA -->

            <v-form ref="formGerais" class="mx-1 mt-3" lazy-validation>
              <v-container class="container pt-2 px-0">
                <v-row class="mt-n5">
                  <v-col cols="6" sm="6" class="pt-2 px-2 pb-0 d-flex" >
                    <v-text-field
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_video_url"
                      :readonly="store_Empreendimento.acao == 'C'"
                      class="input-razao mb-0 mr-1"
                      loader-height="1"
                      background-color="#FFF"
                      label="Vídeo:"
                      placeholder="https://www.youtube.com/embed/tgbNymZ7vqY"
                      light
                      filled
                      outlined
                      required
                      dense />
                      <v-btn
                        width="auto"
                        link
                        max-width="35px"
                        min-width="20px"
                        class="btn white--text px-2"
                        v-if="store_Empreendimento?.empreendimento_edicao?.empreend_video_url"
                        @click="atualizarSrc()"
                        :color="COR_SUBTITULO">
                        <v-icon>
                          mdi-video
                        </v-icon>
                      </v-btn>
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-2 px-2 pb-0">
                    <span>
                      Link do vídeo de apresentação do empreendimento
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-textarea
                    v-model="store_Empreendimento.empreendimento_edicao.empreend_desc_comercial"
                    :readonly="store_Empreendimento.acao == 'C'"
                    class="input-razao mb-0 mr-1"
                    loader-height="1"
                    rows="2"
                    auto-grow
                    background-color="#FFF"
                    label="Descrição resumida empreendimento:"
                    placeholder="Descrição resumida empreendimento:"
                    light
                    maxlength="200"
                    filled
                    counter="200"
                    outlined
                    required
                    dense />
                    
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-2 px-2 pb-0">
                    <span>
                      Descrição resumida do Empreendimento para App e Site
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-textarea
                      v-model="store_Empreendimento.empreendimento_edicao.empreend_desc_comercial_compl"
                      :readonly="store_Empreendimento.acao == 'C'"
                      rows="2"
                      auto-grow
                      class="input-razao mb-0 mr-1"
                      loader-height="1"
                      background-color="#FFF"
                      label="Descrição completa empreendimento:"
                      placeholder="Descrição completa empreendimento"
                      light
                      filled
                      counter
                      outlined
                      required
                      dense />
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-2 px-2 pb-0">
                    <span>
                      Descrição completa do Empreendimento para App e Site
                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-3">
                  <v-col cols="6" sm="6" class="px-2 pb-0">
                    <v-text-field
                    v-model="store_Empreendimento.empreendimento_edicao.empreend_endereco"
                    :readonly="store_Empreendimento.acao == 'C'"
                    rows="2"
                    auto-grow
                    maxlength="150"
                    class="input-razao mb-0 mr-1"
                    loader-height="1"
                    background-color="#FFF"
                    label="Endereço do Empreendimento:"
                    placeholder="Endereço do Empreendimento"
                    light
                    filled
                    outlined
                    required
                    dense />
                  </v-col>
                  <v-col
                    cols="6"
                    sm="6"
                    class="pt-2 px-2 pb-0">
                    <span>
                      Endereço do Empreendimento para App, e Site

                    </span>
                  </v-col>
                </v-row>
                <v-row class="mt-0 pt-0">
                  <v-col cols="12" class="pa-0">
                    <v-card >
                      <v-card-title 
                        class="font-primary font-weight-bold font-weight-medium body-1 d-flex justify-space-between" 
                        :style="{ 'color':COR_SUBTITULO }">
                        <span>Configuração de Contatos</span>
                      </v-card-title>
                      <v-card-text class="pb-2">
                        <v-row class="mt-0">
                          <v-col
                            cols="6"
                            sm="6"
                            class="pt-0 px-2 pb-0">
                            <v-select
                              v-model="cpf_cnpj"
                              @change="handleInputChange('cpf_cnpj', $event)"
                              :items="array_sim_nao"
                              item-text="name"
                              background-color="#FFF"
                              :readonly="store_Empreendimento.acao == 'C'"
                              label="CPF/CNPJ Obrigatório:"
                              light
                              filled
                              outlined
                              required
                              dense />
                          </v-col>
                          <v-col
                            cols="6"
                            style="color: rgba(0, 0, 0, 0.87);"
                            sm="6"
                            class="pt-0">
                            <span>
                              No cadastro de contatos o CPF/CNPJ é obrigatório?
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text class="pb-2">
                        <v-row class="mt-0">
                          <v-col
                            cols="6"
                            sm="6"
                            class="pt-0 px-2 pb-0">
                            <v-select
                              v-model="email"
                              :items="array_sim_nao"
                              @change="handleInputChange('email', $event)"
                              background-color="#FFF"
                              :readonly="store_Empreendimento.acao == 'C'"
                              label="Email Obrigatório:"
                              light
                              item-text="name"
                              filled
                              outlined
                              required
                              dense />
                          </v-col>
                          <v-col
                            cols="6"
                            style="color: rgba(0, 0, 0, 0.87);"
                            sm="6"
                            class="pt-0">
                            <span>
                              No cadastro de contatos o Email é obrigatório?
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-text>
                        <v-row class="mt-0">
                          <v-col
                            cols="6"
                            sm="6"
                            class="pt-0 px-2 pb-0">
                            <v-select
                              v-model="celular"
                              :items="array_sim_nao"
                              background-color="#FFF"
                              :readonly="store_Empreendimento.acao == 'C'"
                              @change="handleInputChange('celular', $event)"
                              label="Celular Obrigatório:"
                              light
                              item-text="name"
                              filled
                              outlined
                              required
                              dense />
                          </v-col>
                          <v-col
                            cols="6"
                            style="color: rgba(0, 0, 0, 0.87);"
                            sm="6"
                            class="pt-0">
                            <span>
                              No cadastro de contatos o Celular é obrigatório?
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card>
        </v-col>
      </v-row>

      <!-- botoes do rodape de Salvar e Cancelar -->
      <v-footer
        v-if="store_Empreendimento.acao != 'C'"
        color="transparent"
        elevation="0"
        class="footer justify-center pt-3">
        <v-btn
          @click="cancelar()"
          id="btn_cancelar"
          ref="btn_cancelar"
          class="mr-4 caption font-weight-medium"
          color="primary"
          text>
          Cancelar
        </v-btn>

        <v-btn
          :loading="loading"
          @click="validateGerais()"
          class="btn caption font-weight-medium white--text"
          color="primary accent-4">
          Salvar
        </v-btn>
      </v-footer>

      <!--------- DIALOG VIDEO ------------------------------->
      <v-container v-if="dialog_video">
        <v-dialog
          class="overflow-hidden pa-0"
          width="420" height="345"
          v-model="dialog_video">
          <v-card v-bind:style="{ 'background-color':COR_SECUNDARIA }" class="container pa-0">
          <v-card-title v-bind:style="{ 'background-color':COR_PRINCIPAL }" class="justify-space-between py-2 px-3">
            <span class="text-white title-page body-1">{{ store_Empreendimento.empreendimento_edicao.empreend_nome }}</span>
            <v-btn @click="dialog_video = false" icon dark color="#F2F6F7">
              <v-icon large class="title">mdi-close</v-icon>
            </v-btn>
          </v-card-title>

            <v-card-text class="wrapper pt-0 px-0 pb-0">
              <div v-show="true">
                <iframe style="border: none" width="420" height="345" :src="src_video">
                </iframe>
              </div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-container>
      <!--------- FIM DIALOG VIDEO ------------------------------->
      
    </div>
  </div>
</template>

<script>
import { COR_SUBTITULO, COR_PRINCIPAL, COR_SECUNDARIA_2, COR_SECUNDARIA } from "../../../services/constantes";
import store_Empreendimento from "../store_Empreendimento";
import FinanceiroModal from "../Financeiro/FinanceiroModal.vue";
import store_site from "../../../store/store_site";
import { API } from "../../../services/API"
import moment from 'moment'

export default {
  name: "Financeiro",
  
  components: {
    FinanceiroModal
  },
  
  data() {
    return {
      store_Empreendimento      : store_Empreendimento,
      
      store_site                : store_site,
      loading                   : false,
      financeiro                : {},
      valid                     : true,
      form                      : false,
      array_select_indice       : null,
      COR_SUBTITULO             : COR_SUBTITULO, 
      COR_PRINCIPAL             : COR_PRINCIPAL, 
      COR_SECUNDARIA_2          : COR_SECUNDARIA_2, 
      COR_SECUNDARIA            : COR_SECUNDARIA, 
      array_pessoa_conta_cedente: null,
      financeiro_edicao_aux     : null,
      tab                       : 0,
      dialog_video              : false,
      src_video                 : '',

      cpf_cnpj : '',
      email : '',
      celular : '',
      changes : {},

      array_sim_nao: [
      { name: "", value: "" },
      { name: "SIM", value: "SIM" },
      { name: "NÃO", value: "NÃO" },
      ],
      opcoes_entrada_dt    : [
        { name: "00 dias", value: "00" },
        { name: "05 dias", value: "05" },
        { name: "10 dias", value: "10" },
        { name: "15 dias", value: "15" },
        { name: "20 dias", value: "20" },
        { name: "25 dias", value: "25" },
        { name: "30 dias", value: "30" },
        { name: "35 dias", value: "35" },
        { name: "40 dias", value: "40" },
        { name: "45 dias", value: "45" },
        { name: "50 dias", value: "50" },
        { name: "55 dias", value: "55" },
        { name: "60 dias", value: "60" },
        { name: "90 dias", value: "90" },
      ],
      opcoes_parcelas_dt   : [
        { name: "00 dias", value: "00" },
        { name: "05 dias", value: "05" },
        { name: "10 dias", value: "10" },
        { name: "15 dias", value: "15" },
        { name: "20 dias", value: "20" },
        { name: "25 dias", value: "25" },
        { name: "30 dias", value: "30" },
        { name: "35 dias", value: "35" },
        { name: "40 dias", value: "40" },
        { name: "45 dias", value: "45" },
        { name: "50 dias", value: "50" },
        { name: "55 dias", value: "55" },
        { name: "60 dias", value: "60" },
        { name: "90 dias", value: "90" },
      ],
      opcoes_boleto      : [
        "SIM", "NÃO",
      ],
      opcoes_contar_meses: [
        "SIM", "NÃO",
      ],
      opcoes_contar_sinal: [
        "SIM", "NÃO",
      ],
      opcoes_gerar_remessa: [
        "SIM", "NÃO",
      ],
      opcoes_emitir   : [
        { name: "Cliente emite", value: 'CLIENTE_EMITE' },
        { name: "Banco emite", value: 'BANCO_EMITE' }
      ],
      opcoes_distribuir   : [
        { name: "Cliente distribui", value: 'CLIENTE_DISTRIBUI' },
        { name: "Banco distribui", value: 'BANCO_DISTRIBUI' }
      ],
      indice_correcao_teste:[
       "Teste1", "Teste2"
      ],

      localRules: [
        (value) => !!value || "O local é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O local deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "O local deve ter menos do que 60 caracteres",
      ],
      indiceProporcionalRules: [
        (value) => !!value || "O indice proporcional de antecipação é obrigatório",
      ],
      diaDeVencimentoRules: [
        (value) => !!value || "O dia de vencimento é obrigatório",
      ],
      instrucoesRules: [
        (value) => !!value || "A instrução é obrigatória",
        (value) =>
          (value && value.length >= 3) ||
          "A instrução deve ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 150) ||
          "A instrução deve ter menos do que 60 caracteres",
      ],
      repetirCedenteRules: [
        (value) => !!value || "Repetir cedente ou antigo é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "Repetir cedente ou antigo ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 60) ||
          "Repetir cedente ou antigo deve ter menos do que 60 caracteres",
      ],
      nomeRules: [
        (value) => !!value || "O nome/tipo de carteira é obrigatório",
        (value) =>
          (value && value.length >= 3) ||
          "O nome/tipo de carteira ter no mínimo 3 caracteres",
        (value) =>
          (value && value.length <= 20) ||
          "O nome/tipo de carteira deve ter menos do que 60 caracteres",
      ],
      codigoRules: [
        (value) => !!value || "O código é obrigatório",
        (value) => ((/^\d+$/.test(value)) || (/^\d+-\d\d?$/.test(value))) || 'O código não é válido',
        (value) =>
        (value && value.length <= 10) ||
          "O código deve ter menos do que 10 caracteres",
      ],
      porcentagemRules: [
        (value) => !!value || "A porcentagem é obrigatória",
        (value) => /^(100(\.0{1,4})?|[1-9][0-9]{0,1}(\.[0-9]{1,4})?)$/.test(value) || 'A porcentagem não é válida',
        // (value) => value.length <= 10 ||
        //   "A porcentagem deve ter menos do que 10 caracteres",
    ],
      correcoesRules: [
        (value) => !!value || "Correções é obrigatório",
        (value) => /^\d+$/.test(value) || 'Correções não é válido',
        //(value) =>
        //  (value && value.length <= 10) ||
        //  "Correções deve ter menos do que 10 caracteres",
      ],
      nomeCedenteRules: [
        (value) => !!value || "O nome é obrigatório",
      ],
      dataRules: [
        (value) => !!value || "A data é obrigatória",
      ],
      boletoRules: [
        (value) => !!value || "O boleto é obrigatório",
      ],
      indiceRules: [
        (value) => !!value || "O índice é obrigatório",
      ],
      contarMesesRules: [
        (value) => !!value || "Contar meses de correção é obrigatório",
      ],
      contarSinalRules: [
        (value) => !!value || "Contar sinal de entrada é obrigatório",
      ],
      gerarRemessaRules: [
        (value) => !!value || "Gerar remessa é obrigatório",
      ],
    };
  },
  created(){
    console.log('created', this.store_Empreendimento.empreendimento_edicao)
  },

  methods: {
    handleInputChange(field, value) {
      this.$set(this.changes, field, value);
      console.log("🚀 ~ handleInputChange ~ this.changes:", this.changes)
    },
    
    atualizarSrc() {
      this.src_video = this.store_Empreendimento.empreendimento_edicao.empreend_video_url
      this.dialog_video = true
      // Defina o novo valor do src
      // this.src_video = this.store_Empreendimento.empreendimento_edicao.empreend_video_url
    },
    async indice(){
      const ls_Rel_Indice = await API.get(`indice`);
      if (ls_Rel_Indice === "Não possui dados") {
        this.array_select_indice = [];
        console.log("🚀 ~ file: Financeiro.vue:1179 ~ indice ~ this.array_select_indice:", this.array_select_indice)
      }
      else{
        this.array_select_indice = ls_Rel_Indice.data.result;
      }
    },
  
    async getPessoaConta(p_params){
      const ls_Rel = await API.get(`getPessoaConta`, { params: { ...p_params } });
      if (ls_Rel.data.result === "Não possui dados") {
        this.array_pessoa_conta_cedente = []
      }
      else{
        this.array_pessoa_conta_cedente = ls_Rel.data.result
      }
    },
    cancelar() {
      this.store_Empreendimento.empreendimento_edicao = {...this.financeiro_edicao_aux};
      if (this.store_Empreendimento.acao == 'I') {
        this.empreendimento_edicaoAux = null;
        this.$router.push("empreendimentoconsulta");
      }
      this.store_Empreendimento.acao = 'C';
    },

    async validateGerais() {
      const lb_valido = this.$refs.formGerais.validate();

      if (lb_valido) {
        let gerais = {}
        gerais.cod_empreendimento               = store_Empreendimento.empreendimento_edicao.cod_empreendimento;
        gerais.empreend_video_url               = store_Empreendimento.empreendimento_edicao.empreend_video_url;
        gerais.empreend_desc_comercial_compl    = store_Empreendimento.empreendimento_edicao.empreend_desc_comercial_compl;
        gerais.empreend_desc_comercial          = store_Empreendimento.empreendimento_edicao.empreend_desc_comercial;
        gerais.empreend_endereco                = store_Empreendimento.empreendimento_edicao.empreend_endereco;

        let json_config = {
          contato : {
            ...this.changes 
          }
        }
        gerais.json_config                      = JSON.stringify(json_config)
        gerais.titulo_ocorrencia                = 'Configurações de empreendimento / Gerais'
       
        var lo_JSON = {
          ...gerais,
        };

        var ls_Res;
        this.loading = true;

        if (this.store_Empreendimento.acao == "E") {
          ls_Res = await this.store_Empreendimento.EmpreendimentoPut(lo_JSON);
        } else {
          ls_Res = await this.store_Empreendimento.EmpreendimentoPost(lo_JSON);
        }
        
        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          // Buscando Novamente o Registro Salvo
          var lo_params = {
            cod_empreendimento: this.$route.params.cod_empreendimento
          };

          const lo_Res = await this.store_Empreendimento.EmpreendimentoGet(lo_params);
          
          if (!!lo_Res){
            console.log("🚀 ~ validateGerais ~ lo_Res:", lo_Res)
            this.store_Empreendimento.empreendimento_edicao = {
              ...lo_Res
            };
          }
          console.log('validateGerais',this.store_Empreendimento.pessoa_edicao)
          this.store_Empreendimento.acao = "C";
          this.empreendimento_edicaoAux = this.store_Empreendimento.empreendimento_edicao;
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
      }
    },
    async validate() {
      const lb_valido = this.$refs.form.validate();

      if (lb_valido) {
        this.financeiro.cod_empreendimento               = store_Empreendimento.empreendimento_edicao.cod_empreendimento;
        this.financeiro.cod_pessoa_conta                 = store_Empreendimento.empreendimento_edicao.cod_pessoa_conta;
        this.financeiro.empreend_boleto_local_pgto       = store_Empreendimento.empreendimento_edicao.empreend_boleto_local_pgto;
        this.financeiro.empreend_boleto_instr1_ent       = store_Empreendimento.empreendimento_edicao.empreend_boleto_instr1_ent;
        this.financeiro.empreend_boleto_instr2_ent       = store_Empreendimento.empreendimento_edicao.empreend_boleto_instr2_ent;
        this.financeiro.empreend_boleto_instr1_parc      = store_Empreendimento.empreendimento_edicao.empreend_boleto_instr1_parc;
        this.financeiro.empreend_boleto_instr2_parc      = store_Empreendimento.empreendimento_edicao.empreend_boleto_instr2_parc;
        this.financeiro.empreend_boleto_convenio         = store_Empreendimento.empreendimento_edicao.empreend_boleto_convenio;
        this.financeiro.empreend_boleto_cedente          = store_Empreendimento.empreendimento_edicao.empreend_boleto_cedente;
        this.financeiro.empreend_boleto_cedente          = store_Empreendimento.empreendimento_edicao.empreend_boleto_cedente;
        this.financeiro.empreend_boleto_carteira         = store_Empreendimento.empreendimento_edicao.empreend_boleto_carteira;
        this.financeiro.empreend_multa                   = store_Empreendimento.empreendimento_edicao.empreend_multa;
        this.financeiro.empreend_juros                   = store_Empreendimento.empreendimento_edicao.empreend_juros;
        this.financeiro.empreend_boleto_registrado       = store_Empreendimento.empreendimento_edicao.empreend_boleto_registrado;
        this.financeiro.cod_indice                       = store_Empreendimento.empreendimento_edicao.empreend_indice.cod_indice;
        this.financeiro.empreend_indiceperiodo           = store_Empreendimento.empreendimento_edicao.empreend_indiceperiodo;
        this.financeiro.cod_indice_acordo                = store_Empreendimento.empreendimento_edicao.cod_indice_acordo;
        this.financeiro.empreend_indice_entrada          = store_Empreendimento.empreendimento_edicao.empreend_indice_entrada;
        this.financeiro.empreend_indice_entrada_sinal    = store_Empreendimento.empreendimento_edicao.empreend_indice_entrada_sinal;
        this.financeiro.empreend_boleto_baixar           = store_Empreendimento.empreendimento_edicao.empreend_boleto_baixar;

        this.financeiro.indice_proporcional_antecipacao  = store_Empreendimento.empreendimento_edicao.indice_proporcional_antecipacao;
        this.financeiro.boleto_emissao                   = store_Empreendimento.empreendimento_edicao.boleto_emissao;
        this.financeiro.boleto_distribuicao              = store_Empreendimento.empreendimento_edicao.boleto_distribuicao;
        this.financeiro.empreend_aplica_reajuste_entr    = store_Empreendimento.empreendimento_edicao.empreend_aplica_reajuste_entr;
        this.financeiro.quant_meses_indice_reajuste      = store_Empreendimento.empreendimento_edicao.quant_meses_indice_reajuste;
        this.financeiro.vcto_dia_util_anterior           = store_Empreendimento.empreendimento_edicao.vcto_dia_util_anterior;
        this.financeiro.empreend_mostra_preco_unidades   = store_Empreendimento.empreendimento_edicao.empreend_mostra_preco_unidades;

        this.financeiro.titulo_ocorrencia                = 'Configurações de empreendimento / Financeiro'

        var lo_JSON = {
          ...this.financeiro,
        };

        var ls_Res;
        this.loading = true;

        if (this.store_Empreendimento.acao == "E") {
          ls_Res = await this.store_Empreendimento.EmpreendimentoPut(lo_JSON);
        } else {
          ls_Res = await this.store_Empreendimento.EmpreendimentoPost(lo_JSON);
        }
        
        if (ls_Res.result && ls_Res.result.message && ls_Res.result.message == 'success') {
          this.store_site.alert_cor = "#00A000";
          this.store_site.alert_timeout = 10000;
          this.store_site.alert_msg = ls_Res.result.result.trim();
          this.store_site.alert = true;

          // Buscando Novamente o Registro Salvo
          var lo_params = {
            cod_empreendimento: ls_Res.result.data.cod_empreendimento
          };

          const lo_Res = await this.store_Empreendimento.EmpreendimentoGet(lo_params);
          if (!!lo_Res)
            this.store_Empreendimento.pessoa_edicao = {
              ...lo_Res
            };
          this.store_Empreendimento.acao = "C";
          console.log("🚀 ~ validate ~ this.store_Empreendimento:", this.store_Empreendimento)
          this.empreendimento_edicaoAux = this.store_Empreendimento.empreendimento_edicao;
        } else {
          this.store_site.alert_cor = "#A00000";
          this.store_site.alert_timeout = 20000;
          this.store_site.alert_msg = ls_Res.errors.trim();
          this.store_site.alert = true;
        }
        this.loading = false;
      }
    },
  },

  async mounted() {
    this.financeiro_edicao_aux = {...this.store_Empreendimento.empreendimento_edicao}
    this.indice();
    this.getPessoaConta({cod_empreendimento : this.store_Empreendimento.empreendimento_edicao.cod_empreendimento});

    if(!!this.store_Empreendimento.empreendimento_edicao.json_config){
      let json_config = JSON.parse(this.store_Empreendimento.empreendimento_edicao.json_config)

      this.cpf_cnpj = json_config.contato.cpf_cnpj
      this.email = json_config.contato.email
      this.celular = json_config.contato.celular
    }
  },

};
</script>

<style scoped>
#Financeiro {
  width: 100%;
  height: 100%;
  padding-top: 4px;
  padding-right: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  clear: both;
}

@media(max-width: 599px) {
  #Financeiro {
    padding-right: 0px;
  }
}

/* ---------- BARRA DE SCROLL ---------- */
#Financeiro::-webkit-scrollbar {
  width: 5px;
}

#Financeiro::-webkit-scrollbar-button {
  padding: 1px;
}

#Financeiro::-webkit-scrollbar-thumb {
  background: #CECECE; 
  border-radius: 50px;
}

#Financeiro::-webkit-scrollbar-thumb:hover {
  background: #A7A7A7; 
  height: 150px;
} 

@media(max-width: 599px) {
  #Financeiro::-webkit-scrollbar {
    width: initial;
  }

  #Financeiro::-webkit-scrollbar-button {
    padding: initial;
  }

  #Financeiro::-webkit-scrollbar-thumb {
    background: initial;
    border-radius: initial;
  }

  #Financeiro::-webkit-scrollbar-thumb:hover {
    background: initial;
    height: initial;
  } 
}

.container {
  max-width: 100%;
}

.header-text {
  color: rgba(0, 0, 0, 0.5);
}

.btn {
  width: 100px;
}

.btn-icon {
  font-size: 20px;
}
.text-white {
    color: #FFFFFF;
    letter-spacing: 0px;
    font-size: 12px!important;
  }
  .theme--light.v-card > .v-card__text, .theme--light.v-card > .v-card__subtitle{
	color:rgba(0, 0, 0, 0.4) !important;
}

.footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  background: var(--COR_SECUNDARIA)!important;
  box-shadow: #edeff1 0px 0px 0px 0px, #edeff1 0px 1px 3px 0px!important;
}

</style>
